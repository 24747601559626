.container-filters {
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  //justify-content: flex-end;
  align-items: center;
  /* btn action filters */
  .action-filters {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .action-filter:last-child {
      margin-right: 0;
    }
  }
  .container-btn {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .action-filter {
    position: relative;
    margin-right: 20px;
    * {
      box-sizing: border-box;
    }
  }
  .action-filter-btn {
    padding: 0;
    position: relative;
    font-size: 0.85em;
    color: var(--dark-text);
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 600;

    * {
      pointer-events: none;
    }

    [class^='icon-'],
    svg {
      margin-right: 4px;
    }

    > span {
      pointer-events: none;
    }
    .icon-download,
    .icon-loader {
      margin-right: 4px;
    }
    .icon-loader {
      display: none;
    }
    &.loading {
      .icon-loader {
        display: inherit;
      }
      .icon-download {
        display: none;
      }
    }
  }
  .action-filter-content {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    border-radius: 3px;
    border: 1px solid #d7d7d7;
    min-height: 72px;
    width: auto;
    max-width: 400px;
    background-color: #ffffff;
    padding: 15px;
    z-index: 99;
  }
  .action-filter-content-end {
    display: none;
    position: absolute;
    top: 30px;
    left: -150px;
    border-radius: 3px;
    border: 1px solid #d7d7d7;
    min-height: 72px;
    width: auto;
    background-color: #ffffff;
    padding: 15px;
    z-index: 99;
  }
  .action-filter-content-active {
    display: inline-block;
  }
  .action-filter-content {
    input {
      border: 1px solid #e8e8e8;
      padding: 1px 0 1px 8px;
    }
    select {
      border: 1px solid #e8e8e8;
    }
  }
  .action-filter-content-label {
    font-weight: bold;
    margin: 0 0 7px 0;
    white-space: nowrap;
    font-size: 0.85em;
    color: #717171;
    text-transform: uppercase;
  }
  .action-filter-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .action-filter-parent-element {
    display: flex;
    flex-wrap: wrap;
  }
  .action-filter-element {
    margin-right: 10px;
    border: 1px solid #d7d7d7;
    &:not(input) {
      margin-top: 5px;
    }
  }
  input.action-filter-element {
    min-height: 22px;
    padding-left: 5px;
    font-size: 0.85em;
    + .plus-wrap button.action-filter-select {
      min-height: 18px;
    }
  }
  .action-filter-row .small-item {
    border: 1px solid var(--main-color);
    border-radius: 3px;
    padding: 0px 4px 0px 4px;
    display: flex;
    text-transform: uppercase;
    color: var(--main-color);
    font-size: 0.85em;
    align-items: baseline;
  }
  .action-filter-row-label {
    margin-right: 4px;
    white-space: nowrap;
  }
  .action-filter-remove {
    padding: 0;
    color: var(--main-color);
    font-size: 0.95em;
    background-color: white;
  }
  .action-filter-btn-add {
    background-color: var(--main-color);
    border-radius: 50%;
    color: #fff;
    height: 21px;
    width: 21px;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    span::before {
      position: relative;
      top: -1px;
      left: -1px;
      font-size: 0.8em;
    }
  }
  .action-filter-btn-cancel {
    background-color: #f1a9a0;
    border-radius: 50%;
    height: 21px;
    width: 21px;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    span::before {
      position: relative;
      top: -1px;
      left: -1px;
      font-size: 0.8em;
    }
  }
  .filter-active {
    position: relative;
    .action-filter-btn {
      color: var(--main-color);
    }
    .action-filter-content {
      top: 35px;
    }
  }
  .plus-wrap {
    position: relative;
  }
  .plus-content {
    display: none;
    position: absolute;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 0px 19px 3px 7px;
    top: 25px;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 100;
    &.show {
      display: block;
    }
  }
  .plus-item {
    white-space: nowrap;
    cursor: pointer;
    font-size: 0.85em;
    margin: 4px 0;
    text-transform: uppercase;
  }
  .plus-toggle {
    cursor: pointer;
    margin-left: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .action-filter-select-wrap {
    display: flex;
    padding: 1px 3px 1px 5px;
    border: 1px solid #e8e8e8;
    background: #ffffff;
    border-radius: 0;
    font-size: 0.85em;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
    + .plus-content {
      top: 0;
    }
  }
  .action-filter-select {
    font-weight: normal;
    text-align: left;
    padding: 0;
    background-color: #fff;
    text-transform: uppercase;
    font-size: 1em;
    white-space: nowrap;

    * {
      pointer-events: none;
    }
  }
  .action-filter-select-wrap .plus-toggle {
    position: relative;
    top: 0;
    right: 0;
  }
  /* FILTERS START TODO: remove? */
  ul.events-list__filters {
    display: flex;
    li {
      margin: 5px;
      label {
        margin: 5px;
      }
    }
  }
  /* FILTERS END */

  @media all and (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    .action-filters {
      margin: 0;
    }
  }
}
