.mat-dialog-container {
    .mat-dialog-title {
        position: relative;
        width: 100%;

        button {
            position: absolute;
            top: -10px;
            right: -10px;
            background: none;
            font-size: 16px;
        }
    }
    .mat-dialog-content {
        ul {
            padding-left: 30px;
        }
    }
}