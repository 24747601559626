.table-container {
  padding: 10px;
  background-color: #fff;
  margin-top: 20px;
}

.table-global-actions {
  display: flex;
  justify-content: space-between;
  height: 32px;
}

.table-global-actions-alarms {
  display: flex;
  margin-bottom: 10px;
}

.table-global-actions-alarms div {
  margin-right: 10px;
}

button span.button-icon-container {
  margin-left: 6px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: var(--pure-white);
  align-items: center;
  font-size: 0.8rem;
  display: inline-flex;
  justify-content: center;
  transition: var(--transition-slow);
}

button.pulse.with-icon span.button-icon-container {
  background-color: var(--icon-dark-blue);
  color: var(--pure-white);
}

.icon-eye.faded {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: var(--sign-in-dark);
  align-items: center;
  font-size: 1em;
  display: inline-flex;
  justify-content: center;
}

button.pulse.with-icon {
  border: 2px solid var(--main-button);
}

button.table-action-red:not(:disabled) {
  border: 2px solid var(--alarm-color-light);
  background-color: var(--alarm-color-light);
  transition-duration: 0.3s;
}

button.table-action-red:not(:disabled):hover {
  background-color: #fff;
  color: var(--alarm-color-light);
  transition-duration: 0.3s;
}

button.table-action-red:not(:disabled):hover span.button-icon-container {
  background-color: var(--alarm-color-light);
}

button.table-action-red:not(:disabled):hover {
  background-color: var(--pure-white);
}

button.table-action-blue:not(:disabled) {
  background-color: var(--main-button);
  border: 2px solid var(--main-button);
  transition-duration: 0.3s;
}

button.table-action-blue-reverse:not(:disabled) {
  background-color: var(--pure-white);
  border: 2px solid var(--main-button);
  color: var(--main-button);
  transition-duration: 0.3s;
}

button.table-action-blue-reverse:not(:disabled):hover {
  background-color: var(--main-button);
  color: var(--pure-white);
  transition-duration: 0.3s;
}

button.table-action-green:not(:disabled) {
  background-color: var(--nav-link-hover);
  border: 2px solid var(--nav-link-hover);
  color: #fff;
  transition-duration: 0.3s;
}

button.table-action-green:not(:disabled):hover {
  background-color: var(--nav-button-green-hover);
  border: 2px solid var(--nav-link-hover);
  transition-duration: 0.3s;
}

button.table-action-blue:not(:disabled):hover {
  background-color: #fff;
  color: var(--main-button);
  transition-duration: 0.3s;
}

button.table-action-blue:not(:disabled):hover span.button-icon-container {
  background-color: var(--button-icon-bgrd);
}

button.btn_app.table-action {
  padding: 5px 15px;
  border-radius: 50px;
  background-color: var(--main-color);
  &:hover {
    background-color: var(--main-color-darker);
  }
  &:disabled {
    background-color: var(--disabled);
  }
}

button:not(.pulse):not(:disabled):hover {
  cursor: pointer;
}

button:not(.pulse):not(:disabled):hover span.button-icon-container {
  color: var(--pure-white);
}

.btn-2 {
  border-radius: 5px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table * {
  box-sizing: border-box;
}

.value-cell {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-size: 0.9rem;
  @media screen and (max-width: 1440px) {
    font-size: 12px;
  }
}

td .value-cell {
  word-break: break-word;
}

td.td-flex span{
  display: flex;
  align-items: center;
  span{
    margin-right: 5px;
  }
}

.wrap-cell .mat-tooltip-trigger{
  cursor: default;
}

/* difference d'affichage */

.table.table-flex-mode,
.table-flex-mode thead,
.table-flex-mode tbody {
  display: block;
}

.table-flex-mode tr {
  display: flex;
  transition: 0.3s;
}

.table-flex-mode td,
.table-flex-mode th {
  flex: 1;
  padding: 0 5px;
  flex-wrap: wrap;
  display: block;
  overflow: hidden;
}

.table-flex-mode td {
  display: flex;
  align-items: center;
}

.table th .value-cell {
  color: var(--light-text);
  font-size: 11px;
}

/* specific à l'app */

thead {
  font-size: 11px;
  font-weight: 600;
  padding: 9px 0;
  background-color: #fff;
  text-transform: uppercase;
}

th {
  text-align: left;
}

tr td:first-child,
tr th:first-child {
  padding-left: 10px;
}

tbody tr {
  border-bottom: 2px solid var(--separation-line);
  padding: 5px 0;
  font-size: 1em;
  &:hover {
    background-color: var(--background-light-gray);
  }
}

tbody td {
  min-height: 36px;
}

.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh;
  background-color: var(--very-light-grey);
}

.table-icon {
  font-size: 15px;
}

.nextLink,
.prevLink {
  cursor: pointer;
  font-size: 15px;
  color: var(--main-color-darker);
}

.link-disabled {
  opacity: 0.1;
  cursor: not-allowed;
}

.pagination-text {
  color: #7b91a5;
}

.first-of-range,
.last-of-range {
  margin: 0 3px;
  font-weight: 600;
  margin: 0 6px;
}

.icon-spacing {
  margin-right: 10px;
}

.date-time-picker {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

$heightTable: 390px;
.table {
  tbody {
    max-height: calc(100vh - #{$heightTable});
    overflow: auto;
  }
}
.tinea-table {
  .table__header-row > div, .tag_line > div {
    flex: 1;
  }
  .tag_line {
    @media screen and (max-width: 1440px) {
      font-size: 12px;
    }
  }
}
