@import 'font';
@import 'table/table-index';
@import 'modal';
@import 'main';
@import 'custom';

app-query-renderer {
  max-height: 300px;
}

$available-color: #3bb537;
$partially-available-color: #fabc2c;
$configured-color: #0793d4;
$unavailable-color: #ee4549;
$unknown-color: #c1c1c1;

.fw-bold{
  font-weight: bold;
}

.station-state-icon {
  border-radius: 50%;
  font-size: 15px;
  margin-right: 5px;
}

.station-state-icon.available, .station-active-icon.CONNECTED {
  color: $available-color;
}

.station-state-icon.unavailable, .station-active-icon.DISCONNECTED {
  color: $unavailable-color;
}

.station-state-icon.partially_available {
  color: $partially-available-color;
}

.station-state-icon.unknown, .station-active-icon.CONFIGURED, .station-active-icon.TO_CONFIGURE {
  color: $unknown-color;
}

.connection-state-icon {
  margin-right: 4px;

  &.connected {
    color: $available-color;
  }

  &.good {
    color: $available-color;
  }

  &.disconnected {
    color: $unavailable-color;
  }

  &.bad {
    color: $unavailable-color;
  }

  &.unknown {
    color: $unknown-color;
  }

  &.configured {
    color: $configured-color;
  }

  &.to_configure {
    color: $partially-available-color;
  }
}

.acquisition-warning-icon {
  color: $partially-available-color;
}

.acquisition-icon {
  color: $available-color;
}

.table-events [data-column_id="ACQUISITION"] {
  justify-content: center;
  text-align: center;
}

.mat-tooltip-trigger {
  cursor: pointer;
}

.multiline-tooltip {
  white-space: pre-line;
}

/* material */
.cubejs-dropdown .mat-form-field-flex,
.global-filters-dropdown .mat-form-field-flex {
  border: 1px solid #d9d9d9;
  border-radius: 0;
  background-color: #fff;
}

.cubejs-form-block-label, .global-filters-form-block-label {
  margin-right: 5px;
}

.cubejs-dropdown.mat-form-field-appearance-fill .mat-form-field-underline::before,
.global-filters-dropdown.mat-form-field-appearance-fill .mat-form-field-underline::before {
  content: none;
}

.cubejs-dropdown.mat-form-field-appearance-fill .mat-form-field-flex,
.global-filters-dropdown.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 .75em 0 .75em;
}

.cubejs-dropdown.mat-form-field-appearance-fill .mat-form-field-infix,
.global-filters-dropdown.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0 0 .75em 0;
}

.cubejs-dropdown.mat-form-field-appearance-fill .mat-select-arrow-wrapper,
.global-filters-dropdown.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(0);
}

// cubejs filter
.cubejs-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.global-filters-form-block {
  margin-left: 10px;
}

.cubejs-actions .cubejs-form-block,
app-select .global-filters-form-block {
  margin-right: 1%;
  width: 24%;
}

.cubejs-actions .cubejs-form-block:last-child,
app-date-range-filter .global-filters-form-block:last-child {
  margin-right: 0;
}

.cubejs-form-block mat-form-field,
.global-filters-form-block mat-form-field {
  width: 100%;
}

.cubejs-form-block.row,
.global-filters-form-block.row {
  display: flex;
  width: 49%;
}

app-select .global-filters-form-block.row {
  width: calc(100% - 10px);
  align-items: center;
}

app-select .global-filters-form-block.row .global-filters-form-block-label {
  padding-bottom: 1.34375em;
  text-transform: capitalize;
}

.cubejs-actions .cubejs-form-block.empty,
.cubejs-actions .global-filters-form-block.empty {
  width: 100%;
}

.cubejs-form-block .form-group.start-date, .cubejs-form-block .form-group.end-date,
.global-filters-form-block .form-group.start-date, .global-filters-form-block .form-group.end-date {
  width: 49%;
}

.cubejs-form-block dp-date-picker,
.global-filters-form-block dp-date-picker {
  width: 100%;
  padding-bottom: 5px;
}

// cubejs title
.station-dashboard-graph-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 0 24px;
}

.station-dashboard-graph-title {
  padding: 16px 0;
  margin: 0;
}

.station-dashboard-graph-body {
  padding: 24px;
}

.station-dashboard-graph-body .dp-input-container,
app-date-range-filter .dp-input-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

//logbook stats filters
app-select#logbook-stat-filter-year .global-filters-form-block {
  margin-left: 0px;
  margin-right: 10px;
  width: 100px;
}

app-select#logbook-stat-filter-category .global-filters-form-block {
  margin-left: 0px;
  margin-right: 10px;
  width: 220px;
}

.btn-add {
  background-color: var(--main-color);
  border-radius: 50%;
  color: #fff;
  height: 21px;
  width: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

//filter n graph
.graph-n-filter-graph {
  margin: 20px 0px;
}

.search-component {
  margin-left: auto;
}

.alert__truncated-data {
  display: inline-block;
  font-size: 0.9rem;
  padding: 8px;
  margin-top: 10px;
}

#station-stat-container-center {
  .wrap-table{
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {

  .cubejs-actions .cubejs-form-block {
    width: auto;
  }
  .cubejs-form-block mat-form-field {
    width: auto;
  }
}

/* app-filter */
.row-filters app-filters-table {
  flex: inherit;
}

.row-filters {
  display: flex;
  justify-content: flex-end;
}

/* table */
.cell-link:hover {
  cursor: pointer;
}

/* menu secondary */
.menu-secondary-wrap {
  display: flex;
}

.menu-secondary-item {
  padding: 5px 10px;
  text-transform: uppercase;
  color: var(--main-color);
  font-weight: bold;
  display: block;
}

.menu-secondary-item.active {
  border-bottom: 3px solid var(--main-color);
}

.menu-secondary-row {
  list-style-type: none;
}

/* synoptics */
.synoptic-form {
  margin-top: 30px;
}

.synoptic-bottom-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

/* sticker */
.deviant-tags-indicator {
  margin: auto;
  width: 40px;
  height: 20px;
  display: inline-block;
  text-align: center;
  background-color: var(--icon-dark-blue);
  border-radius: 50%;
  color: #fff;
}

.actions-container {
  display: flex !important;
  justify-content: flex-end;
  margin: 10px 0;
}


