// This scss file should only contains css rules
// for instance specifics components

/***************************/
// CUSTOM DASHBOARD
/***************************/
.custom-dashboard-indicator-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  // height: 50%; // 50%;
  // min-height: 250px;
  min-width: 48.8%; //850px;
  height: 500px;

  &:first-child {
    margin-right: 30px;
  }
}

.custom-dashboard-indicator-row.w-100{
  margin: 0;
}

.custom-dashboard-graph {
  height: 100%;
  width: 100%;
  margin: 10px 0;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(black, 5%);
  padding: 10px;
  overflow: hidden;
}

.custom-dashboard-title, .custom-widget-title {
  margin: 0 10px;
  padding: 10px 0px;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--main-color);
}

.custom-dashboard-hr, .custom-widget-hr {
  border-bottom: 2px solid var(--main-color);
  margin: 0px 10px 20px 10px;
}

.custom-dashboard-no-data {
  text-align: center;
  font-size: 20px;
  padding-bottom: 1.34375em;
  position: relative;
  top: calc(50% - 83px)
}

// CUSTOM OVERALL INDICATOR
.custom-dashboard-overall-no-data,
.custom-dashboard-overall-data {
  width: max-content;
  text-align: center;
  margin: auto;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.custom-dashboard-indicator-adaptor-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  app-data-source-selector{
    margin-right: 10px;
  }
}

.custom-dashboard-overall-data {
  font-size: 50px;
  padding-bottom: 10px;
  font-weight: bold;
  color: var(--icon-dark-blue);
}

.custom-dashboard-overall-no-data {
  padding-bottom: 25px;
  font-size: 28px;
}

.custom-dashboard-overall-displayed-value-displayed-value {
  margin: 0px 10px;
}

.custom-dashboard-overall-icon-container {
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  width: 50px;
  background-color: var(--pure-white);
  border: 2px solid var(--icon-dark-blue);
  font-size: 32px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-dashboard-overall-date-range-filter .cubejs-form-block.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  dp-date-picker .dp-input-container input {
    width: 80px;
  }
}

//CUSTOM DASHBOARD ECHART GRAPH

.custom-dashboard-echarts-graph {
  margin: 0 5px;
  position: relative;
  top: -60px;
  left: 0px;
  z-index: 1;
}

.custom-dashboard-echarts-date-range-filter {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: calc(100% - 450px);
}

.custom-dashboard-filters {
  display: flex;
}

// CUSTOM DASHBOARD CONTAINER
.custom-dashboard-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  height: calc((100vh - 120px) / 2);
}

.custom-dashboard-site-graph {
  width: 49%;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(black, 5%);
  padding: 10px;
  overflow: hidden;
}

/***************************/
// CUSTOM FORECAST FORMS
/***************************/

.forecast-form {
  margin: 10px;
}

.forecast-form-group {
  margin: 5px;

  &.forecast-form-group-sm {
    width: 100px;
  }

  label {
    text-transform: capitalize;
  }

  .dp-input-container {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0px;
  }
}

.forecast-form-submit-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.forecast-form-submit-btn {
  margin: 5px;
  width: 100px;
}

#forecast-form-months-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#forecast-form-tag-year-group {
  display: flex;
  justify-content: space-between;
}

.forecast-form-tag-group {
  width: 100%;
  max-width: 250px;
}

#delete-forecast-form-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/***************************/
// CUSTOM LOG BOOK
/***************************/
.logbook-form-col-mOpp-datetime {
  &.ng-invalid.ng-touched {
    .dp-input-container {
      border: var(--error-color) 1px solid !important;
    }
  }
}

.mat-tooltip {
  font-size: 12px;
  background-color: var(--medium-text);
  color: #fff !important;
  text-align: justify;
}

.logbook-form-col-event-datetime {
  flex-shrink: 0;
  display: inline-block;
  width: calc((100% - 42px) * 0.08);
}

.logbook-form-col-pu-decoupling-datetime dp-date-picker .dp-input-container,
.logbook-form-col-pu-coupling-datetime dp-date-picker .dp-input-container,
.logbook-form-col-event-datetime dp-date-picker .dp-input-container {
  padding-left: 5px;
}

.logbook-form-col-event-datetime dp-date-picker .dp-input-container input {
  width: 95%;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 12px;
  color: var(--dark-text);
  background-color: var(--pure-white);
}

.logbook-form-col-pu-coupling,
.logbook-form-col-pu-decoupling {
  width: fit-content;

  span {
    display: block;
  }

  .logbook-form-col-pu-coupling-fields,
  .logbook-form-col-pu-decoupling-fields {
    display: flex;
  }
}

.logbook-form-col-pu-decoupling-datetime,
.logbook-form-col-pu-coupling-datetime {
  flex-shrink: 0;
  display: inline-block;
  width: calc((100% - 14px) * 0.18);
  margin-left: 2px;
  margin-right: 2px;
}

.logbook-form-col-pu-decoupling-datetime dp-date-picker .dp-input-container input,
.logbook-form-col-pu-coupling-datetime dp-date-picker .dp-input-container input {
  width: 95%;
  font-size: 12px;
  color: var(--dark-text);
  background-color: var(--pure-white);
}

.logbook-form-col-event-datetime dp-date-picker .dp-input-container,
.logbook-form-col-pu-decoupling-datetime dp-date-picker .dp-input-container,
.logbook-form-col-pu-coupling-datetime dp-date-picker .dp-input-container {
  margin-left: 2px;
  margin-right: 2px;
}

.logbook-form.ng-submitted,
.production-unit-form.ng-submitted {
  dp-date-picker.ng-invalid .dp-input-container,
  select.ng-invalid,
  input:not(.dp-picker-input).ng-invalid {
    border: var(--error-color) 1px solid !important;
  }

  dp-date-picker.ng-valid .dp-input-container,
  select.ng-valid,
  input:not(.dp-picker-input).ng-valid {
    border: var(--success-text) 1px solid !important;
  }
}

.loogbook-filter-container {
  mat-form-field {
    width: 150px !important;
  }


  mat-form-field:nth-child(1) {
    margin-right: 10px;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 .75em 0 .75em;
    background-color: var(--pure-white);
    border: 1px solid #d9d9d9;
    height: 37px;
  }

  .mat-form-field-underline {
    width: 0px;
  }
}

.title-page.title-page-two-column.title-page-align-start {
  justify-content: start;
  margin-bottom: 0px;

  .back {
    margin-left: 10px;
  }
}

.mOppSlider {
  span {
    font-size: 11px;
  }

  .mat-checked {
    .mat-slide-toggle-bar {
      background-color: rgba(23, 58, 102, 0.65);
    }

    .mat-slide-toggle-thumb {
      background-color: var(--main-color);
    }
  }

  .mat-slide-toggle-bar {
    width: 30px;
    height: 11px;

  }
}

.mOppSlider {
  .mat-slide-toggle-thumb {
    height: 15px;
    width: 15px;
  }
}

//MIN WIDTH MEDIA QUERIES
@media screen and (min-width: 700px) {
  .forecast-form-group {
    margin: 10px;

    &.forecast-form-group-sm {
      width: 150px;
    }
  }

  .forecast-form-submit-btn {
    margin: 10px;
    width: 150px;
  }
}

@media screen and (min-width: 1000px) {
  .forecast-form-group {
    margin: 10px 15px;

    &.forecast-form-group-sm {
      width: 200px;
    }
  }

  .forecast-form-submit-btn {
    margin: 10px 15px;
  }
}

@media screen and (min-width: 1300px) {
  .forecast-form-group {
    margin: 10px 20px;

    &.forecast-form-group-sm {
      width: 250px;
    }
  }

  .forecast-form-submit-btn {
    margin: 10px 20px;
  }
}

@media screen and (min-width: 1600px) {
  .forecast-form-group {
    margin: 10px 30px;

    &.forecast-form-group-sm {
      width: 350px;
    }
  }

  .forecast-form-submit-btn {
    margin: 10px 30px;
  }
}

@media screen and (min-width: 2200px) {
  .forecast-form-group {
    margin: 20px 40px;

    &.forecast-form-group-sm {
      width: 450px;
    }
  }

  .forecast-form-submit-btn {
    margin: 20px 40px;
  }
}

//MAX WIDTH MEDIA QUERIES
@media screen and (max-width: 600px) {
  .forecast-form-group.forecast-delete-form-group-element {
    width: 100%;
    max-width: none;

    dp-date-picker {
      width: 100%;
    }
  }
}

//MAX HEIGHT MEDIA QUERIES
@media screen and (max-height: 700px) {
  // CUSTOM OVERALL INDICATOR
  .custom-dashboard-overall-data {
    top: auto;
    bottom: 10px;
  }

  .custom-dashboard-overall-no-data {
    top: auto;
    bottom: 30px;
  }
}

@media screen and (max-height: 900px) {
  .custom-dashboard-overall-data {
    font-size: 32px;
  }

  .custom-dashboard-overall-no-data {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .custom-dashboard-overall-icon-container {
    height: 32px;
    width: 32px;
    font-size: 24px;
    margin: 5px;
  }
}


@media screen and (max-width: 1500px) {
  .logbook-form-col-pu-decoupling-datetime dp-date-picker .dp-input-container input,
  .logbook-form-col-pu-coupling-datetime dp-date-picker .dp-input-container input,
  .logbook-form-col-event-datetime dp-date-picker .dp-input-container input {
    font-size: 10px;
  }
}

@media screen and (max-width: 1300px) {
  .logbook-form-col-pu-decoupling-datetime dp-date-picker .dp-input-container input,
  .logbook-form-col-pu-coupling-datetime dp-date-picker .dp-input-container input,
  .logbook-form-col-event-datetime dp-date-picker .dp-input-container input {
    font-size: 8px;
  }
}
