@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?wxhi3t');
  src:  url('fonts/icomoon.eot?wxhi3t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?wxhi3t') format('truetype'),
    url('fonts/icomoon.woff?wxhi3t') format('woff'),
    url('fonts/icomoon.svg?wxhi3t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity1:before {
  content: "\eb4c";
}
.icon-airplay1:before {
  content: "\eb4d";
}
.icon-alert-circle:before {
  content: "\eb4e";
}
.icon-alert-octagon:before {
  content: "\eb4f";
}
.icon-alert-triangle:before {
  content: "\eb50";
}
.icon-align-center:before {
  content: "\eb51";
}
.icon-align-justify:before {
  content: "\eb52";
}
.icon-align-left:before {
  content: "\eb53";
}
.icon-align-right:before {
  content: "\eb54";
}
.icon-anchor:before {
  content: "\eb55";
}
.icon-aperture1:before {
  content: "\eb56";
}
.icon-archive1:before {
  content: "\eb57";
}
.icon-arrow-down1:before {
  content: "\eb58";
}
.icon-arrow-down-circle:before {
  content: "\eb59";
}
.icon-arrow-down-left1:before {
  content: "\eb5a";
}
.icon-arrow-down-right1:before {
  content: "\eb5b";
}
.icon-arrow-left1:before {
  content: "\eb5c";
}
.icon-arrow-left-circle:before {
  content: "\eb5d";
}
.icon-arrow-right1:before {
  content: "\eb5e";
}
.icon-arrow-right-circle:before {
  content: "\eb5f";
}
.icon-arrow-up1:before {
  content: "\eb60";
}
.icon-arrow-up-circle:before {
  content: "\eb61";
}
.icon-arrow-up-left1:before {
  content: "\eb62";
}
.icon-arrow-up-right1:before {
  content: "\eb63";
}
.icon-at-sign:before {
  content: "\eb64";
}
.icon-award:before {
  content: "\eb65";
}
.icon-bar-chart:before {
  content: "\eb66";
}
.icon-bar-chart-2:before {
  content: "\eb67";
}
.icon-battery:before {
  content: "\eb68";
}
.icon-battery-charging1:before {
  content: "\eb69";
}
.icon-bell1:before {
  content: "\eb6a";
}
.icon-bell-off:before {
  content: "\eb6b";
}
.icon-bluetooth1:before {
  content: "\eb6c";
}
.icon-bold:before {
  content: "\eb6d";
}
.icon-book1:before {
  content: "\eb6e";
}
.icon-book-open1:before {
  content: "\eb6f";
}
.icon-bookmark1:before {
  content: "\eb70";
}
.icon-box:before {
  content: "\eb71";
}
.icon-briefcase1:before {
  content: "\eb72";
}
.icon-calendar1:before {
  content: "\eb73";
}
.icon-camera1:before {
  content: "\eb74";
}
.icon-camera-off:before {
  content: "\eb75";
}
.icon-cast:before {
  content: "\eb76";
}
.icon-check1:before {
  content: "\eb77";
}
.icon-check-circle1:before {
  content: "\eb78";
}
.icon-check-square1:before {
  content: "\eb79";
}
.icon-chevron-down:before {
  content: "\eb7a";
}
.icon-chevron-left:before {
  content: "\eb7b";
}
.icon-chevron-right:before {
  content: "\eb7c";
}
.icon-chevron-up:before {
  content: "\eb7d";
}
.icon-chevrons-down:before {
  content: "\eb7e";
}
.icon-chevrons-left:before {
  content: "\eb7f";
}
.icon-chevrons-right:before {
  content: "\eb80";
}
.icon-chevrons-up:before {
  content: "\eb81";
}
.icon-chrome:before {
  content: "\eb82";
}
.icon-circle1:before {
  content: "\eb83";
}
.icon-clipboard1:before {
  content: "\eb84";
}
.icon-clock1:before {
  content: "\eb85";
}
.icon-cloud1:before {
  content: "\eb86";
}
.icon-cloud-drizzle:before {
  content: "\eb87";
}
.icon-cloud-lightning1:before {
  content: "\eb88";
}
.icon-cloud-off:before {
  content: "\eb89";
}
.icon-cloud-rain1:before {
  content: "\eb8a";
}
.icon-cloud-snow1:before {
  content: "\eb8b";
}
.icon-code1:before {
  content: "\eb8c";
}
.icon-codepen:before {
  content: "\eb8d";
}
.icon-codesandbox:before {
  content: "\eb8e";
}
.icon-coffee1:before {
  content: "\eb8f";
}
.icon-columns:before {
  content: "\eb90";
}
.icon-command1:before {
  content: "\eb91";
}
.icon-compass1:before {
  content: "\eb92";
}
.icon-copy1:before {
  content: "\eb93";
}
.icon-corner-down-left:before {
  content: "\eb94";
}
.icon-corner-down-right:before {
  content: "\eb95";
}
.icon-corner-left-down:before {
  content: "\eb96";
}
.icon-corner-left-up:before {
  content: "\eb97";
}
.icon-corner-right-down:before {
  content: "\eb98";
}
.icon-corner-right-up:before {
  content: "\eb99";
}
.icon-corner-up-left:before {
  content: "\eb9a";
}
.icon-corner-up-right:before {
  content: "\eb9b";
}
.icon-cpu:before {
  content: "\eb9c";
}
.icon-credit-card1:before {
  content: "\eb9d";
}
.icon-crop1:before {
  content: "\eb9e";
}
.icon-crosshair1:before {
  content: "\eb9f";
}
.icon-database1:before {
  content: "\eba0";
}
.icon-delete:before {
  content: "\eba1";
}
.icon-disc:before {
  content: "\eba2";
}
.icon-dollar-sign:before {
  content: "\eba3";
}
.icon-download1:before {
  content: "\eba4";
}
.icon-download-cloud:before {
  content: "\eba5";
}
.icon-droplet:before {
  content: "\eba6";
}
.icon-edit:before {
  content: "\eba7";
}
.icon-edit-2:before {
  content: "\eba8";
}
.icon-edit-3:before {
  content: "\eba9";
}
.icon-external-link:before {
  content: "\ebaa";
}
.icon-eye1:before {
  content: "\ebab";
}
.icon-eye-off:before {
  content: "\ebac";
}
.icon-facebook:before {
  content: "\ebad";
}
.icon-fast-forward1:before {
  content: "\ebae";
}
.icon-feather:before {
  content: "\ebaf";
}
.icon-figma:before {
  content: "\ebb0";
}
.icon-file1:before {
  content: "\ebb1";
}
.icon-file-minus1:before {
  content: "\ebb2";
}
.icon-file-plus1:before {
  content: "\ebb3";
}
.icon-file-text1:before {
  content: "\ebb4";
}
.icon-film:before {
  content: "\ebb5";
}
.icon-filter:before {
  content: "\ebb6";
}
.icon-flag1:before {
  content: "\ebb7";
}
.icon-folder1:before {
  content: "\ebb8";
}
.icon-folder-minus1:before {
  content: "\ebb9";
}
.icon-folder-plus1:before {
  content: "\ebba";
}
.icon-framer:before {
  content: "\ebbb";
}
.icon-frown:before {
  content: "\ebbc";
}
.icon-gift1:before {
  content: "\ebbd";
}
.icon-git-branch1:before {
  content: "\ebbe";
}
.icon-git-commit1:before {
  content: "\ebbf";
}
.icon-git-merge1:before {
  content: "\ebc0";
}
.icon-git-pull-request1:before {
  content: "\ebc1";
}
.icon-github:before {
  content: "\ebc2";
}
.icon-gitlab:before {
  content: "\ebc3";
}
.icon-globe1:before {
  content: "\ebc4";
}
.icon-grid:before {
  content: "\ebc5";
}
.icon-hard-drive1:before {
  content: "\ebc6";
}
.icon-hash1:before {
  content: "\ebc7";
}
.icon-headphones1:before {
  content: "\ebc8";
}
.icon-heart1:before {
  content: "\ebc9";
}
.icon-help-circle:before {
  content: "\ebca";
}
.icon-hexagon1:before {
  content: "\ebcb";
}
.icon-home:before {
  content: "\ebcc";
}
.icon-image1:before {
  content: "\ebcd";
}
.icon-inbox:before {
  content: "\ebce";
}
.icon-info1:before {
  content: "\ebcf";
}
.icon-instagram:before {
  content: "\ebd0";
}
.icon-italic:before {
  content: "\ebd1";
}
.icon-key1:before {
  content: "\ebd2";
}
.icon-layers:before {
  content: "\ebd3";
}
.icon-layout:before {
  content: "\ebd4";
}
.icon-life-buoy:before {
  content: "\ebd5";
}
.icon-link1:before {
  content: "\ebd6";
}
.icon-link-2:before {
  content: "\ebd7";
}
.icon-linkedin:before {
  content: "\ebd8";
}
.icon-list1:before {
  content: "\ebd9";
}
.icon-loader:before {
  content: "\ebda";
}
.icon-lock1:before {
  content: "\ebdb";
}
.icon-log-in:before {
  content: "\ebdc";
}
.icon-log-out:before {
  content: "\ebdd";
}
.icon-mail:before {
  content: "\ebde";
}
.icon-map:before {
  content: "\ebdf";
}
.icon-map-pin1:before {
  content: "\ebe0";
}
.icon-maximize:before {
  content: "\ebe1";
}
.icon-maximize-2:before {
  content: "\ebe2";
}
.icon-meh:before {
  content: "\ebe3";
}
.icon-menu:before {
  content: "\ebe4";
}
.icon-message-circle:before {
  content: "\ebe5";
}
.icon-message-square:before {
  content: "\ebe6";
}
.icon-mic:before {
  content: "\ebe7";
}
.icon-mic-off:before {
  content: "\ebe8";
}
.icon-minimize:before {
  content: "\ebe9";
}
.icon-minimize-2:before {
  content: "\ebea";
}
.icon-minus1:before {
  content: "\ebeb";
}
.icon-minus-circle1:before {
  content: "\ebec";
}
.icon-minus-square:before {
  content: "\ebed";
}
.icon-monitor1:before {
  content: "\ebee";
}
.icon-moon1:before {
  content: "\ebef";
}
.icon-more-horizontal:before {
  content: "\ebf0";
}
.icon-more-vertical:before {
  content: "\ebf1";
}
.icon-mouse-pointer:before {
  content: "\ebf2";
}
.icon-move:before {
  content: "\ebf3";
}
.icon-music:before {
  content: "\ebf4";
}
.icon-navigation:before {
  content: "\ebf5";
}
.icon-navigation-2:before {
  content: "\ebf6";
}
.icon-octagon1:before {
  content: "\ebf7";
}
.icon-package1:before {
  content: "\ebf8";
}
.icon-paperclip1:before {
  content: "\ebf9";
}
.icon-pause1:before {
  content: "\ebfa";
}
.icon-pause-circle1:before {
  content: "\ebfb";
}
.icon-pen-tool:before {
  content: "\ebfc";
}
.icon-percent1:before {
  content: "\ebfd";
}
.icon-phone1:before {
  content: "\ebfe";
}
.icon-phone-call1:before {
  content: "\ebff";
}
.icon-phone-forwarded:before {
  content: "\ec00";
}
.icon-phone-incoming1:before {
  content: "\ec01";
}
.icon-phone-missed:before {
  content: "\ec02";
}
.icon-phone-off:before {
  content: "\ec03";
}
.icon-phone-outgoing1:before {
  content: "\ec04";
}
.icon-pie-chart:before {
  content: "\ec05";
}
.icon-play1:before {
  content: "\ec06";
}
.icon-play-circle1:before {
  content: "\ec07";
}
.icon-plus1:before {
  content: "\ec08";
}
.icon-plus-circle1:before {
  content: "\ec09";
}
.icon-plus-square:before {
  content: "\ec0a";
}
.icon-pocket:before {
  content: "\ec0b";
}
.icon-power1:before {
  content: "\ec0c";
}
.icon-printer1:before {
  content: "\ec0d";
}
.icon-radio:before {
  content: "\ec0e";
}
.icon-refresh-ccw:before {
  content: "\ec0f";
}
.icon-refresh-cw:before {
  content: "\ec10";
}
.icon-repeat1:before {
  content: "\ec11";
}
.icon-rewind1:before {
  content: "\ec12";
}
.icon-rotate-ccw:before {
  content: "\ec13";
}
.icon-rotate-cw:before {
  content: "\ec14";
}
.icon-rss1:before {
  content: "\ec15";
}
.icon-save:before {
  content: "\ec16";
}
.icon-scissors1:before {
  content: "\ec17";
}
.icon-search:before {
  content: "\ec18";
}
.icon-send:before {
  content: "\ec19";
}
.icon-server:before {
  content: "\ec1a";
}
.icon-settings:before {
  content: "\ec1b";
}
.icon-share1:before {
  content: "\ec1c";
}
.icon-share-2:before {
  content: "\ec1d";
}
.icon-shield1:before {
  content: "\ec1e";
}
.icon-shield-off:before {
  content: "\ec1f";
}
.icon-shopping-bag1:before {
  content: "\ec20";
}
.icon-shopping-cart1:before {
  content: "\ec21";
}
.icon-shuffle1:before {
  content: "\ec22";
}
.icon-sidebar:before {
  content: "\ec23";
}
.icon-skip-back1:before {
  content: "\ec24";
}
.icon-skip-forward1:before {
  content: "\ec25";
}
.icon-slack:before {
  content: "\ec26";
}
.icon-slash:before {
  content: "\ec27";
}
.icon-sliders1:before {
  content: "\ec28";
}
.icon-smartphone:before {
  content: "\ec29";
}
.icon-smile:before {
  content: "\ec2a";
}
.icon-speaker:before {
  content: "\ec2b";
}
.icon-square1:before {
  content: "\ec2c";
}
.icon-star1:before {
  content: "\ec2d";
}
.icon-stop-circle1:before {
  content: "\ec2e";
}
.icon-sun1:before {
  content: "\ec2f";
}
.icon-sunrise:before {
  content: "\ec30";
}
.icon-sunset:before {
  content: "\ec31";
}
.icon-tablet:before {
  content: "\ec32";
}
.icon-tag1:before {
  content: "\ec33";
}
.icon-target1:before {
  content: "\ec34";
}
.icon-terminal1:before {
  content: "\ec35";
}
.icon-thermometer:before {
  content: "\ec36";
}
.icon-thumbs-down1:before {
  content: "\ec37";
}
.icon-thumbs-up1:before {
  content: "\ec38";
}
.icon-toggle-left:before {
  content: "\ec39";
}
.icon-toggle-right:before {
  content: "\ec3a";
}
.icon-tool:before {
  content: "\ec3b";
}
.icon-trash1:before {
  content: "\ec3c";
}
.icon-trash-2:before {
  content: "\ec3d";
}
.icon-trello:before {
  content: "\ec3e";
}
.icon-trending-down:before {
  content: "\ec3f";
}
.icon-trending-up:before {
  content: "\ec40";
}
.icon-triangle1:before {
  content: "\ec41";
}
.icon-truck1:before {
  content: "\ec42";
}
.icon-tv:before {
  content: "\ec43";
}
.icon-twitch:before {
  content: "\ec44";
}
.icon-twitter:before {
  content: "\ec45";
}
.icon-type:before {
  content: "\ec46";
}
.icon-umbrella:before {
  content: "\ec47";
}
.icon-underline:before {
  content: "\ec48";
}
.icon-unlock:before {
  content: "\ec49";
}
.icon-upload1:before {
  content: "\ec4a";
}
.icon-upload-cloud:before {
  content: "\ec4b";
}
.icon-user1:before {
  content: "\ec4c";
}
.icon-user-check:before {
  content: "\ec4d";
}
.icon-user-minus1:before {
  content: "\ec4e";
}
.icon-user-plus1:before {
  content: "\ec4f";
}
.icon-user-x:before {
  content: "\ec50";
}
.icon-users1:before {
  content: "\ec51";
}
.icon-video:before {
  content: "\ec52";
}
.icon-video-off:before {
  content: "\ec53";
}
.icon-voicemail1:before {
  content: "\ec54";
}
.icon-volume:before {
  content: "\ec55";
}
.icon-volume-1:before {
  content: "\ec56";
}
.icon-volume-2:before {
  content: "\ec57";
}
.icon-volume-x:before {
  content: "\ec58";
}
.icon-watch1:before {
  content: "\ec59";
}
.icon-wifi:before {
  content: "\ec5a";
}
.icon-wifi-off:before {
  content: "\ec5b";
}
.icon-wind:before {
  content: "\ec5c";
}
.icon-x1:before {
  content: "\ec5d";
}
.icon-x-circle1:before {
  content: "\ec5e";
}
.icon-x-octagon:before {
  content: "\ec5f";
}
.icon-x-square1:before {
  content: "\ec60";
}
.icon-youtube:before {
  content: "\ec61";
}
.icon-zap:before {
  content: "\ec62";
}
.icon-zap-off:before {
  content: "\ec63";
}
.icon-zoom-in:before {
  content: "\ec64";
}
.icon-zoom-out:before {
  content: "\ec65";
}
.icon-activity:before {
  content: "\e900";
}
.icon-airplane:before {
  content: "\e901";
}
.icon-airplay:before {
  content: "\e902";
}
.icon-alarm:before {
  content: "\e903";
}
.icon-android-logo:before {
  content: "\e904";
}
.icon-aperture:before {
  content: "\e905";
}
.icon-archive:before {
  content: "\e906";
}
.icon-archive-box:before {
  content: "\e907";
}
.icon-archive-tray:before {
  content: "\e908";
}
.icon-arrow-arc-left:before {
  content: "\e909";
}
.icon-arrow-arc-right:before {
  content: "\e90a";
}
.icon-arrow-bend-double-up-left:before {
  content: "\e90b";
}
.icon-arrow-bend-double-up-right:before {
  content: "\e90c";
}
.icon-arrow-bend-down-left:before {
  content: "\e90d";
}
.icon-arrow-bend-down-right:before {
  content: "\e90e";
}
.icon-arrow-bend-left-down:before {
  content: "\e90f";
}
.icon-arrow-bend-left-up:before {
  content: "\e910";
}
.icon-arrow-bend-right-down:before {
  content: "\e911";
}
.icon-arrow-bend-right-up:before {
  content: "\e912";
}
.icon-arrow-bend-up-left:before {
  content: "\e913";
}
.icon-arrow-bend-up-right:before {
  content: "\e914";
}
.icon-arrow-circle-down:before {
  content: "\e915";
}
.icon-arrow-circle-down-left:before {
  content: "\e916";
}
.icon-arrow-circle-down-right:before {
  content: "\e917";
}
.icon-arrow-circle-left:before {
  content: "\e918";
}
.icon-arrow-circle-right:before {
  content: "\e919";
}
.icon-arrow-circle-up:before {
  content: "\e91a";
}
.icon-arrow-circle-up-left:before {
  content: "\e91b";
}
.icon-arrow-circle-up-right:before {
  content: "\e91c";
}
.icon-arrow-clockwise:before {
  content: "\e91d";
}
.icon-arrow-counter-clockwise:before {
  content: "\e91e";
}
.icon-arrow-down:before {
  content: "\e91f";
}
.icon-arrow-down-left:before {
  content: "\e920";
}
.icon-arrow-down-right:before {
  content: "\e921";
}
.icon-arrow-elbow-down-left:before {
  content: "\e922";
}
.icon-arrow-elbow-down-right:before {
  content: "\e923";
}
.icon-arrow-elbow-left:before {
  content: "\e924";
}
.icon-arrow-elbow-left-down:before {
  content: "\e925";
}
.icon-arrow-elbow-left-up:before {
  content: "\e926";
}
.icon-arrow-elbow-right:before {
  content: "\e927";
}
.icon-arrow-elbow-right-down:before {
  content: "\e928";
}
.icon-arrow-elbow-right-up:before {
  content: "\e929";
}
.icon-arrow-elbow-up-left:before {
  content: "\e92a";
}
.icon-arrow-elbow-up-right:before {
  content: "\e92b";
}
.icon-arrow-left:before {
  content: "\e92c";
}
.icon-arrow-line-down:before {
  content: "\e92d";
}
.icon-arrow-line-down-left:before {
  content: "\e92e";
}
.icon-arrow-line-down-right:before {
  content: "\e92f";
}
.icon-arrow-line-left:before {
  content: "\e930";
}
.icon-arrow-line-right:before {
  content: "\e931";
}
.icon-arrow-line-up:before {
  content: "\e932";
}
.icon-arrow-line-up-left:before {
  content: "\e933";
}
.icon-arrow-line-up-right:before {
  content: "\e934";
}
.icon-arrow-right:before {
  content: "\e935";
}
.icon-arrows-clockwise:before {
  content: "\e936";
}
.icon-arrows-counter-clockwise:before {
  content: "\e937";
}
.icon-arrows-down-up:before {
  content: "\e938";
}
.icon-arrows-in:before {
  content: "\e939";
}
.icon-arrows-in-cardinal:before {
  content: "\e93a";
}
.icon-arrows-in-simple:before {
  content: "\e93b";
}
.icon-arrows-left-right:before {
  content: "\e93c";
}
.icon-arrows-out:before {
  content: "\e93d";
}
.icon-arrows-out-cardinal:before {
  content: "\e93e";
}
.icon-arrows-out-simple:before {
  content: "\e93f";
}
.icon-arrow-square-down:before {
  content: "\e940";
}
.icon-arrow-square-down-left:before {
  content: "\e941";
}
.icon-arrow-square-down-right:before {
  content: "\e942";
}
.icon-arrow-square-left:before {
  content: "\e943";
}
.icon-arrow-square-right:before {
  content: "\e944";
}
.icon-arrow-square-up:before {
  content: "\e945";
}
.icon-arrow-square-up-left:before {
  content: "\e946";
}
.icon-arrow-square-up-right:before {
  content: "\e947";
}
.icon-arrow-u-down-left:before {
  content: "\e948";
}
.icon-arrow-u-down-right:before {
  content: "\e949";
}
.icon-arrow-u-left-down:before {
  content: "\e94a";
}
.icon-arrow-u-left-up:before {
  content: "\e94b";
}
.icon-arrow-up:before {
  content: "\e94c";
}
.icon-arrow-up-left:before {
  content: "\e94d";
}
.icon-arrow-up-right:before {
  content: "\e94e";
}
.icon-arrow-u-right-down:before {
  content: "\e94f";
}
.icon-arrow-u-right-up:before {
  content: "\e950";
}
.icon-arrow-u-up-left:before {
  content: "\e951";
}
.icon-arrow-u-up-right:before {
  content: "\e952";
}
.icon-article:before {
  content: "\e953";
}
.icon-asterisk:before {
  content: "\e954";
}
.icon-at:before {
  content: "\e955";
}
.icon-backspace:before {
  content: "\e956";
}
.icon-bag:before {
  content: "\e957";
}
.icon-bandaids:before {
  content: "\e958";
}
.icon-bank:before {
  content: "\e959";
}
.icon-barbell:before {
  content: "\e95a";
}
.icon-barcode:before {
  content: "\e95b";
}
.icon-battery-charging:before {
  content: "\e95c";
}
.icon-battery-charging-vertical:before {
  content: "\e95d";
}
.icon-battery-empty:before {
  content: "\e95e";
}
.icon-battery-full:before {
  content: "\e95f";
}
.icon-battery-high:before {
  content: "\e960";
}
.icon-battery-low:before {
  content: "\e961";
}
.icon-battery-medium:before {
  content: "\e962";
}
.icon-battery-warning:before {
  content: "\e963";
}
.icon-battery-warning-vertical:before {
  content: "\e964";
}
.icon-bed:before {
  content: "\e965";
}
.icon-bell:before {
  content: "\e966";
}
.icon-bell-simple:before {
  content: "\e967";
}
.icon-bell-simple-slash:before {
  content: "\e968";
}
.icon-bell-slash:before {
  content: "\e969";
}
.icon-bicycle:before {
  content: "\e96a";
}
.icon-bluetooth:before {
  content: "\e96b";
}
.icon-bluetooth-connected:before {
  content: "\e96c";
}
.icon-bluetooth-slash:before {
  content: "\e96d";
}
.icon-bluetooth-x:before {
  content: "\e96e";
}
.icon-book:before {
  content: "\e96f";
}
.icon-book-bookmark:before {
  content: "\e970";
}
.icon-bookmark:before {
  content: "\e971";
}
.icon-bookmarks:before {
  content: "\e972";
}
.icon-bookmark-simple:before {
  content: "\e973";
}
.icon-book-open:before {
  content: "\e974";
}
.icon-briefcase:before {
  content: "\e975";
}
.icon-briefcase-simple:before {
  content: "\e976";
}
.icon-broadcast:before {
  content: "\e977";
}
.icon-browser:before {
  content: "\e978";
}
.icon-browsers:before {
  content: "\e979";
}
.icon-browsers-simple:before {
  content: "\e97a";
}
.icon-buildings:before {
  content: "\e97b";
}
.icon-bus:before {
  content: "\e97c";
}
.icon-calendar:before {
  content: "\e97d";
}
.icon-calendar-blank:before {
  content: "\e97e";
}
.icon-calendar-x:before {
  content: "\e97f";
}
.icon-camera:before {
  content: "\e980";
}
.icon-camera-slash:before {
  content: "\e981";
}
.icon-car:before {
  content: "\e982";
}
.icon-caret-circle-double-down:before {
  content: "\e983";
}
.icon-caret-circle-double-left:before {
  content: "\e984";
}
.icon-caret-circle-double-right:before {
  content: "\e985";
}
.icon-caret-circle-double-up:before {
  content: "\e986";
}
.icon-caret-circle-down:before {
  content: "\e987";
}
.icon-caret-circle-left:before {
  content: "\e988";
}
.icon-caret-circle-right:before {
  content: "\e989";
}
.icon-caret-circle-up:before {
  content: "\e98a";
}
.icon-caret-double-down:before {
  content: "\e98b";
}
.icon-caret-double-left:before {
  content: "\e98c";
}
.icon-caret-double-right:before {
  content: "\e98d";
}
.icon-caret-double-up:before {
  content: "\e98e";
}
.icon-caret-down:before {
  content: "\e98f";
}
.icon-caret-left:before {
  content: "\e990";
}
.icon-caret-right:before {
  content: "\e991";
}
.icon-caret-up:before {
  content: "\e992";
}
.icon-car-simple:before {
  content: "\e993";
}
.icon-cell-signal-full:before {
  content: "\e994";
}
.icon-cell-signal-high:before {
  content: "\e995";
}
.icon-cell-signal-low:before {
  content: "\e996";
}
.icon-cell-signal-medium:before {
  content: "\e997";
}
.icon-cell-signal-none:before {
  content: "\e998";
}
.icon-cell-signal-slash:before {
  content: "\e999";
}
.icon-cell-signal-x:before {
  content: "\e99a";
}
.icon-chart-bar:before {
  content: "\e99b";
}
.icon-chart-bar-horizontal:before {
  content: "\e99c";
}
.icon-chart-line:before {
  content: "\e99d";
}
.icon-chart-line-up:before {
  content: "\e99e";
}
.icon-chart-pie:before {
  content: "\e99f";
}
.icon-chart-pie-slice:before {
  content: "\e9a0";
}
.icon-chat:before {
  content: "\e9a1";
}
.icon-chat-centered:before {
  content: "\e9a2";
}
.icon-chat-centered-dots:before {
  content: "\e9a3";
}
.icon-chat-centered-text:before {
  content: "\e9a4";
}
.icon-chat-circle:before {
  content: "\e9a5";
}
.icon-chat-circle-dots:before {
  content: "\e9a6";
}
.icon-chat-circle-text:before {
  content: "\e9a7";
}
.icon-chat-dots:before {
  content: "\e9a8";
}
.icon-chats:before {
  content: "\e9a9";
}
.icon-chats-circle:before {
  content: "\e9aa";
}
.icon-chats-teardrop:before {
  content: "\e9ab";
}
.icon-chat-teardrop:before {
  content: "\e9ac";
}
.icon-chat-teardrop-dots:before {
  content: "\e9ad";
}
.icon-chat-teardrop-text:before {
  content: "\e9ae";
}
.icon-chat-text:before {
  content: "\e9af";
}
.icon-check:before {
  content: "\e9b0";
}
.icon-check-circle:before {
  content: "\e9b1";
}
.icon-check-square:before {
  content: "\e9b2";
}
.icon-check-square-offset:before {
  content: "\e9b3";
}
.icon-circle:before {
  content: "\e9b4";
}
.icon-circle-half:before {
  content: "\e9b5";
}
.icon-circle-half-tilt:before {
  content: "\e9b6";
}
.icon-circles-four:before {
  content: "\e9b7";
}
.icon-circles-three:before {
  content: "\e9b8";
}
.icon-circles-three-plus:before {
  content: "\e9b9";
}
.icon-clipboard:before {
  content: "\e9ba";
}
.icon-clipboard-text:before {
  content: "\e9bb";
}
.icon-clock:before {
  content: "\e9bc";
}
.icon-clock-afternoon:before {
  content: "\e9bd";
}
.icon-clock-clockwise:before {
  content: "\e9be";
}
.icon-clock-counter-clockwise:before {
  content: "\e9bf";
}
.icon-closed-captioning:before {
  content: "\e9c0";
}
.icon-cloud:before {
  content: "\e9c1";
}
.icon-cloud-arrow-down:before {
  content: "\e9c2";
}
.icon-cloud-arrow-up:before {
  content: "\e9c3";
}
.icon-cloud-check:before {
  content: "\e9c4";
}
.icon-cloud-lightning:before {
  content: "\e9c5";
}
.icon-cloud-rain:before {
  content: "\e9c6";
}
.icon-cloud-slash:before {
  content: "\e9c7";
}
.icon-cloud-snow:before {
  content: "\e9c8";
}
.icon-club:before {
  content: "\e9c9";
}
.icon-code:before {
  content: "\e9ca";
}
.icon-code-simple:before {
  content: "\e9cb";
}
.icon-coffee:before {
  content: "\e9cc";
}
.icon-command:before {
  content: "\e9cd";
}
.icon-compass:before {
  content: "\e9ce";
}
.icon-copy:before {
  content: "\e9cf";
}
.icon-copy-simple:before {
  content: "\e9d0";
}
.icon-corners-in:before {
  content: "\e9d1";
}
.icon-corners-out:before {
  content: "\e9d2";
}
.icon-credit-card:before {
  content: "\e9d3";
}
.icon-crop:before {
  content: "\e9d4";
}
.icon-crosshair:before {
  content: "\e9d5";
}
.icon-crosshair-simple:before {
  content: "\e9d6";
}
.icon-cube:before {
  content: "\e9d7";
}
.icon-cursor:before {
  content: "\e9d8";
}
.icon-database:before {
  content: "\e9d9";
}
.icon-device-mobile:before {
  content: "\e9da";
}
.icon-device-mobile-camera:before {
  content: "\e9db";
}
.icon-device-mobile-speaker:before {
  content: "\e9dc";
}
.icon-device-tablet:before {
  content: "\e9dd";
}
.icon-device-tablet-camera:before {
  content: "\e9de";
}
.icon-device-tablet-speaker:before {
  content: "\e9df";
}
.icon-diamond:before {
  content: "\e9e0";
}
.icon-dice-five:before {
  content: "\e9e1";
}
.icon-dice-four:before {
  content: "\e9e2";
}
.icon-dice-one:before {
  content: "\e9e3";
}
.icon-dice-six:before {
  content: "\e9e4";
}
.icon-dice-three:before {
  content: "\e9e5";
}
.icon-dice-two:before {
  content: "\e9e6";
}
.icon-divide:before {
  content: "\e9e7";
}
.icon-dots-nine:before {
  content: "\e9e8";
}
.icon-dots-three:before {
  content: "\e9e9";
}
.icon-dots-three-circle:before {
  content: "\e9ea";
}
.icon-dots-three-circle-vertical:before {
  content: "\e9eb";
}
.icon-dots-three-outline:before {
  content: "\e9ec";
}
.icon-dots-three-outline-vertical:before {
  content: "\e9ed";
}
.icon-dots-three-vertical:before {
  content: "\e9ee";
}
.icon-download:before {
  content: "\e9ef";
}
.icon-download-simple:before {
  content: "\e9f0";
}
.icon-dribbble-logo:before {
  content: "\e9f1";
}
.icon-drop:before {
  content: "\e9f2";
}
.icon-eject:before {
  content: "\e9f3";
}
.icon-envelope:before {
  content: "\e9f4";
}
.icon-envelope-open:before {
  content: "\e9f5";
}
.icon-envelope-simple:before {
  content: "\e9f6";
}
.icon-envelope-simple-open:before {
  content: "\e9f7";
}
.icon-equals:before {
  content: "\e9f8";
}
.icon-eraser:before {
  content: "\e9f9";
}
.icon-eye:before {
  content: "\e9fa";
}
.icon-eye-closed:before {
  content: "\e9fb";
}
.icon-eye-slash:before {
  content: "\e9fc";
}
.icon-facebook-logo:before {
  content: "\e9fd";
}
.icon-faders:before {
  content: "\e9fe";
}
.icon-faders-horizontal:before {
  content: "\e9ff";
}
.icon-fast-forward:before {
  content: "\ea00";
}
.icon-figma-logo:before {
  content: "\ea01";
}
.icon-file:before {
  content: "\ea02";
}
.icon-file-arrow-down:before {
  content: "\ea03";
}
.icon-file-minus:before {
  content: "\ea04";
}
.icon-file-plus:before {
  content: "\ea05";
}
.icon-file-search:before {
  content: "\ea06";
}
.icon-file-text:before {
  content: "\ea07";
}
.icon-file-x:before {
  content: "\ea08";
}
.icon-fingerprint:before {
  content: "\ea09";
}
.icon-fingerprint-simple:before {
  content: "\ea0a";
}
.icon-fire:before {
  content: "\ea0b";
}
.icon-flag:before {
  content: "\ea0c";
}
.icon-flashlight:before {
  content: "\ea0d";
}
.icon-floppy-disk:before {
  content: "\ea0e";
}
.icon-folder:before {
  content: "\ea0f";
}
.icon-folder-minus:before {
  content: "\ea10";
}
.icon-folder-notch:before {
  content: "\ea11";
}
.icon-folder-notch-minus:before {
  content: "\ea12";
}
.icon-folder-notch-open:before {
  content: "\ea13";
}
.icon-folder-notch-plus:before {
  content: "\ea14";
}
.icon-folder-open:before {
  content: "\ea15";
}
.icon-folder-plus:before {
  content: "\ea16";
}
.icon-folders:before {
  content: "\ea17";
}
.icon-folder-simple:before {
  content: "\ea18";
}
.icon-folder-simple-minus:before {
  content: "\ea19";
}
.icon-folder-simple-plus:before {
  content: "\ea1a";
}
.icon-fork-knife:before {
  content: "\ea1b";
}
.icon-framer-logo:before {
  content: "\ea1c";
}
.icon-funnel:before {
  content: "\ea1d";
}
.icon-funnel-simple:before {
  content: "\ea1e";
}
.icon-game-controller:before {
  content: "\ea1f";
}
.icon-gear:before {
  content: "\ea20";
}
.icon-gear-six:before {
  content: "\ea21";
}
.icon-gif:before {
  content: "\ea22";
}
.icon-gift:before {
  content: "\ea23";
}
.icon-git-branch:before {
  content: "\ea24";
}
.icon-git-commit:before {
  content: "\ea25";
}
.icon-git-diff:before {
  content: "\ea26";
}
.icon-git-fork:before {
  content: "\ea27";
}
.icon-git-merge:before {
  content: "\ea28";
}
.icon-git-pull-request:before {
  content: "\ea29";
}
.icon-globe:before {
  content: "\ea2a";
}
.icon-globe-hemisphere-east:before {
  content: "\ea2b";
}
.icon-globe-hemisphere-west:before {
  content: "\ea2c";
}
.icon-globe-simple:before {
  content: "\ea2d";
}
.icon-globe-stand:before {
  content: "\ea2e";
}
.icon-google-logo:before {
  content: "\ea2f";
}
.icon-google-play-logo:before {
  content: "\ea30";
}
.icon-grid-four:before {
  content: "\ea31";
}
.icon-hand:before {
  content: "\ea32";
}
.icon-handbag:before {
  content: "\ea33";
}
.icon-hand-pointing:before {
  content: "\ea34";
}
.icon-hard-drive:before {
  content: "\ea35";
}
.icon-hard-drives:before {
  content: "\ea36";
}
.icon-hash:before {
  content: "\ea37";
}
.icon-hash-straight:before {
  content: "\ea38";
}
.icon-headphones:before {
  content: "\ea39";
}
.icon-heart:before {
  content: "\ea3a";
}
.icon-heartbeat:before {
  content: "\ea3b";
}
.icon-heart-straight:before {
  content: "\ea3c";
}
.icon-hexagon:before {
  content: "\ea3d";
}
.icon-horse:before {
  content: "\ea3e";
}
.icon-hourglass:before {
  content: "\ea3f";
}
.icon-hourglass-high:before {
  content: "\ea40";
}
.icon-hourglass-low:before {
  content: "\ea41";
}
.icon-hourglass-medium:before {
  content: "\ea42";
}
.icon-hourglass-simple:before {
  content: "\ea43";
}
.icon-hourglass-simple-high:before {
  content: "\ea44";
}
.icon-hourglass-simple-low:before {
  content: "\ea45";
}
.icon-hourglass-simple-medium:before {
  content: "\ea46";
}
.icon-house:before {
  content: "\ea47";
}
.icon-house-line:before {
  content: "\ea48";
}
.icon-house-simple:before {
  content: "\ea49";
}
.icon-identification-card:before {
  content: "\ea4a";
}
.icon-image:before {
  content: "\ea4b";
}
.icon-image-square:before {
  content: "\ea4c";
}
.icon-info:before {
  content: "\ea4d";
}
.icon-instagram-logo:before {
  content: "\ea4e";
}
.icon-intersect:before {
  content: "\ea4f";
}
.icon-jeep:before {
  content: "\ea50";
}
.icon-key:before {
  content: "\ea51";
}
.icon-keyboard:before {
  content: "\ea52";
}
.icon-leaf:before {
  content: "\ea53";
}
.icon-lightbulb:before {
  content: "\ea54";
}
.icon-lightning:before {
  content: "\ea55";
}
.icon-lightning-slash:before {
  content: "\ea56";
}
.icon-link:before {
  content: "\ea57";
}
.icon-link-break:before {
  content: "\ea58";
}
.icon-linkedin-logo:before {
  content: "\ea59";
}
.icon-link-simple:before {
  content: "\ea5a";
}
.icon-link-simple-break:before {
  content: "\ea5b";
}
.icon-link-simple-horizontal:before {
  content: "\ea5c";
}
.icon-link-simple-horizontal-break:before {
  content: "\ea5d";
}
.icon-list:before {
  content: "\ea5e";
}
.icon-list-bullets:before {
  content: "\ea5f";
}
.icon-list-dashes:before {
  content: "\ea60";
}
.icon-list-numbers:before {
  content: "\ea61";
}
.icon-list-plus:before {
  content: "\ea62";
}
.icon-lock:before {
  content: "\ea63";
}
.icon-lock-key:before {
  content: "\ea64";
}
.icon-lock-key-open:before {
  content: "\ea65";
}
.icon-lock-laminated:before {
  content: "\ea66";
}
.icon-lock-laminated-open:before {
  content: "\ea67";
}
.icon-lock-open:before {
  content: "\ea68";
}
.icon-lock-simple:before {
  content: "\ea69";
}
.icon-lock-simple-open:before {
  content: "\ea6a";
}
.icon-magnifying-glass:before {
  content: "\ea6b";
}
.icon-magnifying-glass-minus:before {
  content: "\ea6c";
}
.icon-magnifying-glass-plus:before {
  content: "\ea6d";
}
.icon-map-pin:before {
  content: "\ea6e";
}
.icon-map-pin-line:before {
  content: "\ea6f";
}
.icon-map-trifold:before {
  content: "\ea70";
}
.icon-martini:before {
  content: "\ea71";
}
.icon-math-operations:before {
  content: "\ea72";
}
.icon-medal:before {
  content: "\ea73";
}
.icon-medium-logo:before {
  content: "\ea74";
}
.icon-megaphone:before {
  content: "\ea75";
}
.icon-microphone:before {
  content: "\ea76";
}
.icon-microphone-slash:before {
  content: "\ea77";
}
.icon-minus:before {
  content: "\ea78";
}
.icon-minus-circle:before {
  content: "\ea79";
}
.icon-monitor:before {
  content: "\ea7a";
}
.icon-moon:before {
  content: "\ea7b";
}
.icon-mouse:before {
  content: "\ea7c";
}
.icon-music-note:before {
  content: "\ea7d";
}
.icon-music-notes:before {
  content: "\ea7e";
}
.icon-music-note-simple:before {
  content: "\ea7f";
}
.icon-music-notes-simple:before {
  content: "\ea80";
}
.icon-navigation-arrow:before {
  content: "\ea81";
}
.icon-newspaper:before {
  content: "\ea82";
}
.icon-newspaper-clipping:before {
  content: "\ea83";
}
.icon-note:before {
  content: "\ea84";
}
.icon-note-blank:before {
  content: "\ea85";
}
.icon-notebook:before {
  content: "\ea86";
}
.icon-note-pencil:before {
  content: "\ea87";
}
.icon-number-circle-eight:before {
  content: "\ea88";
}
.icon-number-circle-five:before {
  content: "\ea89";
}
.icon-number-circle-four:before {
  content: "\ea8a";
}
.icon-number-circle-nine:before {
  content: "\ea8b";
}
.icon-number-circle-one:before {
  content: "\ea8c";
}
.icon-number-circle-seven:before {
  content: "\ea8d";
}
.icon-number-circle-six:before {
  content: "\ea8e";
}
.icon-number-circle-three:before {
  content: "\ea8f";
}
.icon-number-circle-two:before {
  content: "\ea90";
}
.icon-number-circle-zero:before {
  content: "\ea91";
}
.icon-number-eight:before {
  content: "\ea92";
}
.icon-number-five:before {
  content: "\ea93";
}
.icon-number-four:before {
  content: "\ea94";
}
.icon-number-nine:before {
  content: "\ea95";
}
.icon-number-one:before {
  content: "\ea96";
}
.icon-number-seven:before {
  content: "\ea97";
}
.icon-number-six:before {
  content: "\ea98";
}
.icon-number-square-eight:before {
  content: "\ea99";
}
.icon-number-square-five:before {
  content: "\ea9a";
}
.icon-number-square-four:before {
  content: "\ea9b";
}
.icon-number-square-nine:before {
  content: "\ea9c";
}
.icon-number-square-one:before {
  content: "\ea9d";
}
.icon-number-square-seven:before {
  content: "\ea9e";
}
.icon-number-square-six:before {
  content: "\ea9f";
}
.icon-number-square-three:before {
  content: "\eaa0";
}
.icon-number-square-two:before {
  content: "\eaa1";
}
.icon-number-square-zero:before {
  content: "\eaa2";
}
.icon-number-three:before {
  content: "\eaa3";
}
.icon-number-two:before {
  content: "\eaa4";
}
.icon-number-zero:before {
  content: "\eaa5";
}
.icon-nut:before {
  content: "\eaa6";
}
.icon-octagon:before {
  content: "\eaa7";
}
.icon-package:before {
  content: "\eaa8";
}
.icon-paint-bucket:before {
  content: "\eaa9";
}
.icon-paperclip:before {
  content: "\eaaa";
}
.icon-paper-plane:before {
  content: "\eaab";
}
.icon-paper-plane-right:before {
  content: "\eaac";
}
.icon-paper-plane-tilt:before {
  content: "\eaad";
}
.icon-path:before {
  content: "\eaae";
}
.icon-pause:before {
  content: "\eaaf";
}
.icon-pause-circle:before {
  content: "\eab0";
}
.icon-peace:before {
  content: "\eab1";
}
.icon-pedestrian:before {
  content: "\eab2";
}
.icon-pen:before {
  content: "\eab3";
}
.icon-pencil:before {
  content: "\eab4";
}
.icon-pencil-circle:before {
  content: "\eab5";
}
.icon-pencil-line:before {
  content: "\eab6";
}
.icon-pencil-simple:before {
  content: "\eab7";
}
.icon-pen-nib:before {
  content: "\eab8";
}
.icon-percent:before {
  content: "\eab9";
}
.icon-phone:before {
  content: "\eaba";
}
.icon-phone-call:before {
  content: "\eabb";
}
.icon-phone-disconnect:before {
  content: "\eabc";
}
.icon-phone-incoming:before {
  content: "\eabd";
}
.icon-phone-outgoing:before {
  content: "\eabe";
}
.icon-phone-slash:before {
  content: "\eabf";
}
.icon-phone-x:before {
  content: "\eac0";
}
.icon-phosphor-logo:before {
  content: "\eac1";
}
.icon-play:before {
  content: "\eac2";
}
.icon-play-circle:before {
  content: "\eac3";
}
.icon-plus:before {
  content: "\eac4";
}
.icon-plus-circle:before {
  content: "\eac5";
}
.icon-plus-minus:before {
  content: "\eac6";
}
.icon-power:before {
  content: "\eac7";
}
.icon-printer:before {
  content: "\eac8";
}
.icon-prohibit:before {
  content: "\eac9";
}
.icon-prohibit-inset:before {
  content: "\eaca";
}
.icon-push-pin:before {
  content: "\eacb";
}
.icon-push-pin-slash:before {
  content: "\eacc";
}
.icon-qr-code:before {
  content: "\eacd";
}
.icon-question:before {
  content: "\eace";
}
.icon-radical:before {
  content: "\eacf";
}
.icon-receipt:before {
  content: "\ead0";
}
.icon-record:before {
  content: "\ead1";
}
.icon-rectangle:before {
  content: "\ead2";
}
.icon-rectangles-two:before {
  content: "\ead3";
}
.icon-repeat:before {
  content: "\ead4";
}
.icon-repeat-once:before {
  content: "\ead5";
}
.icon-rewind:before {
  content: "\ead6";
}
.icon-rocket:before {
  content: "\ead7";
}
.icon-rocket-launch:before {
  content: "\ead8";
}
.icon-rss:before {
  content: "\ead9";
}
.icon-rss-simple:before {
  content: "\eada";
}
.icon-scissors:before {
  content: "\eadb";
}
.icon-screencast:before {
  content: "\eadc";
}
.icon-share:before {
  content: "\eadd";
}
.icon-share-network:before {
  content: "\eade";
}
.icon-shield:before {
  content: "\eadf";
}
.icon-shield-check:before {
  content: "\eae0";
}
.icon-shield-chevron:before {
  content: "\eae1";
}
.icon-shield-slash:before {
  content: "\eae2";
}
.icon-shield-warning:before {
  content: "\eae3";
}
.icon-shopping-bag:before {
  content: "\eae4";
}
.icon-shopping-bag-open:before {
  content: "\eae5";
}
.icon-shopping-cart:before {
  content: "\eae6";
}
.icon-shopping-cart-simple:before {
  content: "\eae7";
}
.icon-shuffle:before {
  content: "\eae8";
}
.icon-shuffle-angular:before {
  content: "\eae9";
}
.icon-shuffle-simple:before {
  content: "\eaea";
}
.icon-sign-in:before {
  content: "\eaeb";
}
.icon-sign-out:before {
  content: "\eaec";
}
.icon-sketch-logo:before {
  content: "\eaed";
}
.icon-skip-back:before {
  content: "\eaee";
}
.icon-skip-forward:before {
  content: "\eaef";
}
.icon-sliders:before {
  content: "\eaf0";
}
.icon-sliders-horizontal:before {
  content: "\eaf1";
}
.icon-smiley:before {
  content: "\eaf2";
}
.icon-smiley-blank:before {
  content: "\eaf3";
}
.icon-smiley-meh:before {
  content: "\eaf4";
}
.icon-smiley-nervous:before {
  content: "\eaf5";
}
.icon-smiley-sad:before {
  content: "\eaf6";
}
.icon-smiley-sticker:before {
  content: "\eaf7";
}
.icon-smiley-x-eyes:before {
  content: "\eaf8";
}
.icon-sort-ascending:before {
  content: "\eaf9";
}
.icon-sort-descending:before {
  content: "\eafa";
}
.icon-spade:before {
  content: "\eafb";
}
.icon-speaker-high:before {
  content: "\eafc";
}
.icon-speaker-low:before {
  content: "\eafd";
}
.icon-speaker-none:before {
  content: "\eafe";
}
.icon-speaker-simple-high:before {
  content: "\eaff";
}
.icon-speaker-simple-low:before {
  content: "\eb00";
}
.icon-speaker-simple-none:before {
  content: "\eb01";
}
.icon-speaker-simple-slash:before {
  content: "\eb02";
}
.icon-speaker-simple-x:before {
  content: "\eb03";
}
.icon-speaker-slash:before {
  content: "\eb04";
}
.icon-speaker-x:before {
  content: "\eb05";
}
.icon-square:before {
  content: "\eb06";
}
.icon-square-half:before {
  content: "\eb07";
}
.icon-squares-four:before {
  content: "\eb08";
}
.icon-stack:before {
  content: "\eb09";
}
.icon-stack-simple:before {
  content: "\eb0a";
}
.icon-star:before {
  content: "\eb0b";
}
.icon-stop:before {
  content: "\eb0c";
}
.icon-stop-circle:before {
  content: "\eb0d";
}
.icon-storefront:before {
  content: "\eb0e";
}
.icon-sun:before {
  content: "\eb0f";
}
.icon-sun-dim:before {
  content: "\eb10";
}
.icon-table:before {
  content: "\eb11";
}
.icon-tag:before {
  content: "\eb12";
}
.icon-tag-simple:before {
  content: "\eb13";
}
.icon-target:before {
  content: "\eb14";
}
.icon-terminal:before {
  content: "\eb15";
}
.icon-text:before {
  content: "\eb16";
}
.icon-text-align-center:before {
  content: "\eb17";
}
.icon-text-align-justify:before {
  content: "\eb18";
}
.icon-text-align-left:before {
  content: "\eb19";
}
.icon-text-align-right:before {
  content: "\eb1a";
}
.icon-thumbs-down:before {
  content: "\eb1b";
}
.icon-thumbs-up:before {
  content: "\eb1c";
}
.icon-ticket:before {
  content: "\eb1d";
}
.icon-timer:before {
  content: "\eb1e";
}
.icon-tote:before {
  content: "\eb1f";
}
.icon-train:before {
  content: "\eb20";
}
.icon-train-regional:before {
  content: "\eb21";
}
.icon-train-simple:before {
  content: "\eb22";
}
.icon-translate:before {
  content: "\eb23";
}
.icon-trash:before {
  content: "\eb24";
}
.icon-trash-simple:before {
  content: "\eb25";
}
.icon-tray:before {
  content: "\eb26";
}
.icon-trend-down:before {
  content: "\eb27";
}
.icon-trend-up:before {
  content: "\eb28";
}
.icon-triangle:before {
  content: "\eb29";
}
.icon-truck:before {
  content: "\eb2a";
}
.icon-twitter-logo:before {
  content: "\eb2b";
}
.icon-upload:before {
  content: "\eb2c";
}
.icon-upload-simple:before {
  content: "\eb2d";
}
.icon-user:before {
  content: "\eb2e";
}
.icon-user-circle:before {
  content: "\eb2f";
}
.icon-user-circle-gear:before {
  content: "\eb30";
}
.icon-user-circle-minus:before {
  content: "\eb31";
}
.icon-user-circle-plus:before {
  content: "\eb32";
}
.icon-user-gear:before {
  content: "\eb33";
}
.icon-user-minus:before {
  content: "\eb34";
}
.icon-user-plus:before {
  content: "\eb35";
}
.icon-user-rectangle:before {
  content: "\eb36";
}
.icon-users:before {
  content: "\eb37";
}
.icon-user-square:before {
  content: "\eb38";
}
.icon-vibrate:before {
  content: "\eb39";
}
.icon-video-camera:before {
  content: "\eb3a";
}
.icon-video-camera-slash:before {
  content: "\eb3b";
}
.icon-voicemail:before {
  content: "\eb3c";
}
.icon-warning:before {
  content: "\eb3d";
}
.icon-warning-circle:before {
  content: "\eb3e";
}
.icon-warning-octagon:before {
  content: "\eb3f";
}
.icon-watch:before {
  content: "\eb40";
}
.icon-wheelchair:before {
  content: "\eb41";
}
.icon-wifi-high:before {
  content: "\eb42";
}
.icon-wifi-low:before {
  content: "\eb43";
}
.icon-wifi-medium:before {
  content: "\eb44";
}
.icon-wifi-none:before {
  content: "\eb45";
}
.icon-wifi-slash:before {
  content: "\eb46";
}
.icon-wifi-x:before {
  content: "\eb47";
}
.icon-x:before {
  content: "\eb48";
}
.icon-x-circle:before {
  content: "\eb49";
}
.icon-x-square:before {
  content: "\eb4a";
}
.icon-youtube-logo:before {
  content: "\eb4b";
}
