:root {
  /* colors */
  --pure-white: #fff;
  --main-color: #20518c;
  --main-color-darker: #173a66;
  --button-color-dark: var(--main-color-darker);
  --background-light-blue: #01d5d7;
  --background-light-gray: #e6e6e6;
  --nav-link-hover: #61c86c;
  --nav-button-green: #61c86c;
  --nav-button-green-hover: #62ce6d;
  --dark-text: #3b3b3b;
  --very-dark-text: #222222;
  --light-text: #aaaaaa;
  --medium-text: #808080;
  --separation-line: #e6e6e6;
  --checkbox: #b8b8b8;
  --light-border: #b0b0b0;
  --medium-border: #808080;
  --very-light-grey: #f6f6f6;
  --energy-production: #6397c1;
  --main-button: var(--main-color-darker);
  --button-icon-bgrd: #1e8bc3;
  --water: #00adef;
  --irradiance: #00adef;
  --error-color: #ff0000;
  --danger-color: #dc3545;
  --alarm-color: #ff787c;
  --alarm-color-light: #f1a9a05e;
  --sign-in-dark: #004750;
  --icon-dark-blue: #00518c;
  --disabled: #e7e7e7;
  --success-text: #159c35;
  --success-background: #155724;
  --alarm-no-data-color: #86e2d5;
  /* fonts */
  --main-font: 'Open Sans', sans-serif;
  --data-font: 'Open Sans', sans-serif;
  /* Borders */
  --border-radius: 15px;
  --border-radius-big: 30px;
  /* Box shadow */
  --box-shadow: 0 0 6px #e4e4e4;
  /* Alarms colors */
  --alarm-level-null: #606060;
  --alarm-level1: #f3e547;
  --alarm-level2: #fcb63c;
  --alarm-level3: #f31810;
  /* Transitions */
  --transition-slow: all 0.4s linear;
  --transition-fast: all 0.2s linear;
}

* {
  box-sizing: border-box;
}

.maintenance-container {
  margin: auto;
  text-align: center;
  display: flex;
  height: 100vh;
  align-items: center;
}

.maintenance-title {
  font-size: 22px;
  font-weight: bold;
  color: var(--main-color);
  margin: 10px;
}

.maintenance-message-container {
  text-align: center;
  width: 100%;
  background-color: #FFF;
  padding: 50px;
}

body {
  font-family: var(--main-font);
  font-size: 14px;
  background-color: #f8f8f8;
  color: var(--dark-text);
  margin: 0;
}

h1 {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
}

.home .right-content,
.home .right-content--extended,
.map .right-content,
.map .right-content--extended {
  padding: 0 !important;
}

.registration .main,
.change-password .main {
  padding: 3% 5%;
}

.main {
  /* 326px = height footer + height header */
  min-height: calc(100vh - 326px);
}

.flex {
  display: flex;
}

.flex-item-baseline {
  align-items: baseline;
}

.pointer {
  cursor: pointer;
}

.width-min {
  min-width: 120px;
}

/* FORMS */

.label-form {
  font-size: 12px;
  text-transform: uppercase;
}

.form-group {
  margin: 10px 0;
}

.notLogged {
  margin: auto;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.table-global-actions button span {
  border: 1px solid transparent;
}

li a {
  border-color: var(--light-border);
}

a {
  text-decoration: unset;
  outline: none;
}

.normal-link {
  font-weight: 700;
  color: var(--button-color-dark);
  transition-duration: 0.2s;
}

.normal-link:hover {
  color: var(--main-color);
  transition-duration: 0.2s;
}

.input-container {
  margin: 7px 0;
}

.form-control {
  font-family: 'Open Sans';
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #b0b0b0;
  padding: 10px;
  font-size: 1em;

  &.border-danger {
    border-color: var(--error-color);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: var(--medium-text);
    // For Safari
    -webkit-text-fill-color: var(--medium-text);
    opacity: 0.8;
    background-color: var(--disabled);
  }
}

.mr-1 {
  margin-right: 5px;
}

.form-group > label {
  display: block;
  margin-bottom: 8px;
  &.required {
    &::after {
      content: '*';
      margin-left: 4px;
      font-size: 0.9em;
      color: red;
    }
  }
}

input[type='radio'],
input[type='checkbox'] {
  position: absolute;
  left: -9999px;
  + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-left: 20px;
    line-height: 20px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 14px;
      height: 14px;
      border: 1px solid var(--checkbox);
      border-radius: 4px;
      background: #fff;
    }
  }
  &.border-danger + label::before {
    border-color: var(--danger-color);
  }
  &:checked + label {
    &::after {
      content: '\eb77';
      font-family: 'icomoon';
      font-size: 12px;
      line-height: 1;
      position: absolute;
      left: 2px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &:disabled + label {
    &::before,
    &::after {
      background: var(--disabled);
    }
  }
}

input[type='radio'] {
  &:checked + label::after {
    $size: 8px;
    content: '';
    width: $size;
    height: $size;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    background: var(--main-color);
    border-radius: 50%;
  }
  &:disabled + label::after {
    opacity: 0.7;
    filter: grayscale(1);
  }
}

input[type='radio'] + label:before {
  border-radius: 50%;
}

select {
  background: white
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23000000'><polygon points='0,0 12,0 6,6'/></svg>")
    no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: calc(50% + 2px);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.btn_app {
  font-family: var(--main-font);
  border-radius: 4px;
  border: none;
  background-color: var(--button-color-dark);
  text-transform: uppercase;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  padding: 8px 0;
  transition-duration: 0.2s;
}

.btn_app:hover {
  background-color: var(--background-light-blue);
  color: white;
  transition-duration: 0.3s;
}

.btn_large {
  width: 130px;
}

.btn_reverse {
  color: var(--button-color-dark);
  background-color: var(--pure-white);
  border: 1px solid var(--pure-white);
  transition-duration: 0.2s;
}

.btn_reverse:hover {
  color: var(--pure-white);
  background-color: var(--main-color);
  transition-duration: 0.2s;
}

button:focus {
  outline: unset !important;
}

button {
  border: none;
}

button.with-icon {
  margin: 0px 10px;
  font-size: 11px;
  transition: var(--transition-fast);
  font-size: 0.87rem;
  padding: 7px 10px 7px 22px;
}

button {
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.6;
  }
}

button:disabled span {
  border: 1px solid var(--light-border);
}

.dp-popup dp-day-time-calendar button {
  cursor: auto;
}

button i {
  cursor: pointer;
}

button:disabled i {
  color: var(--separation-line);
}

button.pulse > span {
  height: auto;
  animation: rotate 0.5s linear infinite;
  background-color: var(--icon-dark-blue);
}

.form-group.form-check {
  margin: 25px 0;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

h1,
p,
button:disabled i {
  cursor: default;
}

::placeholder {
  font-family: 'Open Sans';
  color: #ababab;
  opacity: unset;
  font-size: 1em;
}

input:focus {
  outline: none;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.full-height {
  /* Firefox */
  height: -moz-calc(97vh - 335px);
  /* WebKit */
  height: -webkit-calc(97vh - 335px);
  /* Opera */
  height: -o-calc(97vh - 335px);
  /* Standard */
  height: calc(97vh - 335px);
}

.error {
  color: var(--error-color);
}

#header {
  height: 52px;
}

#wrap-main {
  height: 100%;
}

.login #wrap-main {
  background-color: #fff;
}

#main {
  border-radius: 5px;
  height: 100%;
}

#main-content {
  height: 100%;
  /* overflow: hidden; */
}

.login #main {
  box-shadow: none;
  height: auto;
}

.login #main-content {
  overflow: auto;
  height: auto;
}

/* *** OKTA START *** */

.primary-auth-form.o-form.o-form-edit-mode {
  margin-bottom: 30px;
}

#okta-sign-in {
  width: 315px;
}

.okta-form-title.o-form-head {
  color: var(--main-color);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px;
}

.o-form-content {
  display: flex;
  flex-direction: column;
}

.o-form-content.o-form-theme {
  margin-bottom: 15px;
}

.o-form-fieldset {
  margin-bottom: 30px;
}

.okta-form-label.o-form-label {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 5px;
}

.o-form-input input:not([type='checkbox']):not(.button) {
  height: 3.1em;
  width: 286px;
  background-color: rgb(0, 0, 0, 0);
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 3px;
  padding-left: 10px;
}

.auth-content-inner {
  display: flex;
  justify-content: center;
}

#okta-signin-submit,
#idp-discovery-submit {
  font-family: var(--main-font);
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  padding: 8px 0;
  background-color: var(--button-color-dark);
  width: 130px;
  transition-duration: 0.2s;
}

#okta-signin-submit:hover,
#idp-discovery-submit:hover {
  color: var(--button-color-dark);
  background-color: #01d5d7;
  transition-duration: 0.2s;
}

.o-form-button-bar {
  text-align: center;
}

.auth-footer {
  border-top: 3px solid var(--main-color);
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  display: none;
}

.auth-footer a {
  color: var(--dark-text);
  text-transform: uppercase;
  text-decoration: none;
}

.auth-footer a:hover {
  color: var(--main-color);
}

.okta-form-input-error {
  color: var(--alarm-color);
  margin-top: 10px;
}

.infobox-error {
  color: var(--alarm-color);
  margin-bottom: 20px;
}

.invalid-feedback {
  margin-top: 10px;
  color: var(--alarm-color);
  font-size: 12px;
}

/* *** OKTA END *** */

/* iOS install popup START */

.jw-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jw-modal-body ol li::before {
  font-family: sans-serif;
}

.jw-modal-body .btn {
  border-radius: 25px;
}

.img-modal {
  width: 9%;
  background: transparent;
  vertical-align: bottom;
}

/* *** iOS install popup END *** */

/* *** DateTimePiker START *** */

.wrap-date {
  border: 1px solid #e6e6e6;
  background: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.wrap-date-picker-action {
  display: flex;
}

.wrap-date-picker-action button {
  margin-right: 5px;
}
.error-date {
  .dp-input-container {
    border: 1px solid var(--error-color);
  }
}
.dp-input-container {
  background: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid var(--light-border);
  padding: 0 20px 0 10px;
  border-radius: 3px;
  &::after {
    content: '\e97e';
    font-family: 'icomoon';
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    cursor: pointer;
  }
}

#selectFormatedDate {
  font-size: 14px;
  font-family: 'Open Sans';
  &:focus {
    outline-color: var(--main-color);
  }
}

dp-date-picker .dp-input-container input {
  border: none;
  border-radius: 0;
  height: 26px;
  padding: 0;
  margin: auto 3px;
  font-family: 'Open Sans';
  font-size: 14px;
  width: 135px;
}

dp-date-picker dp-day-calendar .dp-calendar-wrapper,
dp-date-picker dp-day-time-calendar dp-time-select {
  border: 1px solid var(--light-border);
}

dp-date-picker.ng-invalid .dp-input-container input {
  border-color: var(--alarm-color);
}

dp-date-picker .dp-calendar-week button:not(:disabled):hover {
  background-color: var(--main-color);
  color: var(--pure-white);
}

dp-date-picker .dp-nav-header-btn {
  font-size: 17px;
  text-transform: capitalize;
  color: var(--main-color-darker);
  font-weight: 700;
  padding-left: 0px;
}

dp-day-calendar dp-calendar-nav .dp-calendar-nav-container {
  height: 36px;
  border: 1px solid var(--light-border);
  border-bottom: none;
}

dp-day-calendar dp-calendar-nav .dp-nav-btns-container {
  display: flex;
  align-items: center;
}

dp-calendar-nav .dp-nav-btns-container button {
  font-size: 12px;
  width: auto;
}

dp-calendar-nav .dp-nav-btns-container .dp-current-location-btn {
  width: 16px;
}

dp-calendar-nav button.dp-calendar-nav-left::before,
dp-calendar-nav button.dp-calendar-nav-right::before {
  height: 9px;
  width: 9px;
}

dp-month-calendar .dp-calendar-month {
  width: 48px !important;
  height: 48px !important;
  margin: 3px;
}

dp-day-calendar .dp-weekdays {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-border);
}

dp-day-calendar .dp-weekdays .dp-calendar-weekday {
  display: initial;
  width: auto;
  border: none;
  padding: 2px 8px;
}

dp-day-calendar .dp-calendar-wrapper.dp-hide-near-month {
  border-top: none;
}

dp-day-calendar .dp-calendar-day {
  margin: 2px 8px;
}

dp-day-time-calendar dp-day-calendar .dp-selected {
  background: var(--main-button);
  color: var(--pure-white);
}

dp-month-calendar .dp-calendar-month.dp-selected {
  background: var(--main-button) !important;
}

dp-day-calendar .dp-selected:hover {
  background: var(--icon-dark-blue);
  transition: var(--transition-fast);
  color: var(--pure-white);
}

dp-month-calendar .dp-calendar-month.dp-selected:hover {
  background: var(--icon-dark-blue) !important;
  transition: all 0.2s linear !important;
  color: var(--pure-white) !important;
}

dp-day-calendar .dp-calendar-day.dp-current-month.dp-current-day {
  border: 1px solid var(--main-color-darker);
}

dp-day-calendar .dp-selected:disabled {
  background: var(--alarm-color);
}

dp-time-select .dp-time-select-control button {
  width: 25px;
  height: 25px;
}

dp-time-select button.dp-time-select-control-up::before {
  top: 1px !important;
  left: -1px;
}

dp-time-select button.dp-time-select-control-down::before {
  top: -3px;
  left: -1px;
}

.dp-calendar-nav-container,
.dp-weekdays,
dp-day-time-calendar dp-time-select .dp-time-select-controls {
  background-color: var(--pure-white);
}

dp-day-time-calendar button:disabled {
  cursor: default;
  background-color: #e6e6e6;
  border: 2px solid #e6e6e6;
  color: #bdbebe;
}

dp-calendar-nav div *,
dp-calendar-nav div *:hover,
dp-time-select .dp-time-select-controls * {
  background-color: unset;
}

.popup-info {
  position: absolute;
  top: 18px;
  right: -10px;
  padding: 10px;
  display: none;
  z-index: 10000;
}

.element_with_popup:hover .popup-info {
  display: block;
}

.popup-info-content {
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  font-weight: normal;
  cursor: default;
}

.popup-info-content li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  white-space: nowrap;
  justify-content: space-between;
}

.popup-info-content li:last-child {
  margin-bottom: 0;
}

.popup-info-content .icon {
  background-color: #53d4ee;
  border-radius: 50%;
  padding: 3px 4px 5px 4px;
  margin-right: 5px;
}

.popup-info-content-text {
  font-size: 1.1rem;
}
.popup-equipment-name {
  margin-right: 10px;
}
.popup-of-txt{
  margin: 0 5px;
}

.popup-station-name {
  margin-right: 18px;
}

.site_indicators .popup-info,
.indicators_list .popup-info {
  text-transform: uppercase;
  font-size: 21px;
  font-family: 'Open Sans';
  color: #00518c;
}

.element_with_popup {
  position: relative;
  cursor: pointer;
}

/* icons */

.icon-indicator {
  font-size: 0.75rem;
  border-radius: 50%;
  padding: 4px;
  background-color: #ffffff;
  border: 1px solid;
}

.icon-indicator.no-data {
  border: none;
}

.icon-alarm {
  background-color: var(--alarm-color);
  border: none;
  color: #ffffff;
}

.icon-alarm.no-data {
  border: 1px solid var(--alarm-color);
  color: var(--alarm-color);
  background: none;
}

.icon-power {
  color: var(--icon-dark-blue);
}

.icon-power.no-data {
  background-color: var(--icon-dark-blue);
  color: #ffffff;
}

.icon-water {
  border-color: var(--water);
  color: var(--water);
}

.icon-water.no-data {
  background-color: var(--water);
  color: #ffffff;
}

/* value icon */

.icon-alarm + span {
  color: var(--alarm-color);
}

.icon-alarm.no-data + span {
  color: var(--alarm-no-data-color);
}

.icon-power + span {
  color: var(--icon-dark-blue);
}

.icon-power.no-data + span {
  color: var(--icon-dark-blue);
}

.icon-water + span {
  color: var(--water);
}

.icon-water.no-data + span {
  color: var(--water);
}

/* *** DateTimePiker END *** */

/* Large screens */

@media all and (min-width: 1200px) {
  .container {
    max-width: 80vw;
  }
}

@media screen and (min-width: 576px) {
  #okta-sign-in {
    width: 380px;
  }
}

/* MY OWN CSS */

/* general */

/* pages */

app-station,
app-station > * {
  height: calc(100% - 20px);
}

/* MAP POPUP */

.leaflet-popup-content {
  margin: 0 !important;
  padding: 10px;
  width: 100% !important;
}

.leaflet-popup {
  width: 250px !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 3px !important;
}

.popupContainer {
  padding: 5px;
}

.popupTitle {
  padding-bottom: 5px;
  border-bottom: 2px solid #e8e8e8;
}

.popupTitle span {
  text-transform: uppercase;
  color: #555555;
  font-weight: 600;
  font-size: 14px;
}

.indicatorContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.dateContainer {
  color: #979797;
}

.noIndicator {
  padding: 10px;
}

.indicator {
  font-size: 18px;
  margin-bottom: 5px;
}
.site-indicator{
  font-size: 14px;
  margin-bottom: 5px;
}

/* Spinner */

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  height: 1em;
  line-height: 1;
  animation: anim-rotate 2s infinite linear;
  text-shadow: 0 0 0.25em rgba(255, 255, 255, 0.3);
}

.spinner--steps {
  animation: anim-rotate 1s infinite steps(8);
}

.spinner--steps2 {
  animation: anim-rotate 1s infinite steps(12);
}

a.back {
  color: var(--main-font);
  text-decoration: underline;
  transition: 0.3s;
  &:hover {
    color: var(--dark-text);
  }
}

/******************** BUTTON TEMPLATE ********************/
.btn {
  font-family: 'Open Sans';
  border-radius: 50px;
  padding: 0 20px;
  min-height: 35px;
  line-height: 35px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  transition: 0.3s;
  &--primary {
    color: white;
    border: 2px solid;
    border-color: var(--main-color);
    background-color: var(--main-color);
    &:hover {
      opacity: 0.8;
    }
  }
  &--outline-main-color {
    color: var(--main-color);
    border: 2px solid var(--main-color);
    background-color: white;
    &:hover {
      color: white;
      background-color: var(--main-color);
    }
  }
  &--outline-grey-color {
    color: var(--medium-text);
    border: 2px solid var(--light-border);
    background-color: white;
    &:hover {
      color: white;
      background-color: var(--light-border);
    }
  }

  &--danger {
    color: #fff;
    background-color: var(--danger-color);
    border-color: var(--danger-color);
    &:hover {
      opacity: 0.9;
    }
  }
  &--outline-danger-color {
    color: var(--danger-color);
    border: 2px solid var(--danger-color);
    background-color: white;
    &:hover {
      color: white;
      background-color: var(--danger-color);
    }
  }

  &:disabled {
    color: var(--light-text);
    border: 2px solid var(--disabled);
    background-color: var(--disabled);
  }

  &--text {
    position: relative;
    overflow: hidden;
    padding: 0 20px;
    color: var(--main-color);
    border: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: 1.2em;
    transition: 0.3s;

    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &__left {
      padding-right: 10px;
      &::before {
        content: '<';
        left: 5px;
      }
    }
    &__right {
      padding-left: 10px;
      &::before {
        content: '>';
        right: 5px;
      }
    }

    &::after {
      content: '';
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 2px;
      background-color: var(--main-color);
      text-decoration: underline;
      transform: translateX(-100%);
      transition: 0.3s;
    }

    &:hover::after {
      transform: translateX(0);
    }
  }
}

// Layout
$displays: 'none', 'flex', 'inline-flex', 'inline-block', 'block';
@each $display in $displays {
  .d-#{$display} {
    display: #{$display} !important;
  }
}

/* ??? wtf */
.col-1 {
  flex: 8.33%;
  max-width: 8.33%;
}
.col-2 {
  flex: 16.6%;
  max-width: 16.6%;
}
.col-3 {
  flex: 25%;
  max-width: 25%;
}
.col-4 {
  flex: 33.33%;
  max-width: 33.33%;
}
.col-5 {
  flex: 41.6%;
  max-width: 41.6%;
}
.col-6 {
  flex: 50%;
  max-width: 50%;
}
.col-7 {
  flex: 58.3%;
  max-width: 58.3%;
}
.col-8 {
  flex: 66.6%;
  max-width: 66.6%;
}
.col-9 {
  flex: 75%;
  max-width: 75%;
}
.col-10 {
  flex: 83.3%;
  max-width: 83.3%;
}
.col-11 {
  flex: 91.6%;
  max-width: 91.6%;
}
.col-12 {
  flex: 100%;
  max-width: 100%;
}

.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.text-capitalize {
  text-transform: capitalize;
}

$margins: '0', '5', '10', '15', '20', '25', '30', '35', '40';
@each $margin in $margins {
  .m-#{$margin} {
    margin: #{$margin}px !important;
  }
  .mt-#{$margin} {
    margin-top: #{$margin}px !important;
  }
  .mb-#{$margin} {
    margin-bottom: #{$margin}px !important;
  }
  .ml-#{$margin} {
    margin-left: #{$margin}px !important;
  }
  .mr-#{$margin} {
    margin-right: #{$margin}px !important;
  }
  .mx-#{$margin} {
    margin-right: #{$margin}px !important;
    margin-left: #{$margin}px !important;
  }
  .my-#{$margin} {
    margin-top: #{$margin}px !important;
    margin-bottom: #{$margin}px !important;
  }
}

$paddings: '0', '5', '10', '15', '20';
@each $padding in $paddings {
  .p-#{$padding} {
    padding: #{$padding}px !important;
  }
  .pt-#{$padding} {
    padding-top: #{$padding}px !important;
  }
  .pb-#{$padding} {
    padding-bottom: #{$padding}px !important;
  }
  .pl-#{$padding} {
    padding-left: #{$padding}px !important;
  }
  .pr-#{$padding} {
    padding-right: #{$padding}px !important;
  }
  .px-#{$padding} {
    padding-right: #{$padding}px !important;
    padding-left: #{$padding}px !important;
  }
  .py-#{$padding} {
    padding-top: #{$padding}px !important;
    padding-bottom: #{$padding}px !important;
  }
}

$textAligns: 'center', 'left', 'right';
@each $textAlign in $textAligns {
  .text-#{$textAlign} {
    text-align: #{$textAlign};
  }
}

.text {
  &--danger {
    color: var(--danger-color);
  }
}

.pointer-events--none {
  pointer-events: none;
}

// Bootstrap inspired
.alert {
  margin-top: 5px;
  padding: 4px 8px;
  font-size: 0.8rem;
  border-radius: 4px;

  &-danger {
    color: var(--error-color);
  }

  &-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
}

.font-size-normal {
  font-size: 1em;
}

/***************** MATERIAL ELEMENTS *****************/
.mat-option {
  font-size: 1em;
}

.mat-dialog-title {
  text-align: center;
}

.mat-tooltip {
  font-size: 14px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* toggle component shared */
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(0 0 0 / 38%);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fafafa;
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: var(--main-color-darker);
}

/***************** COMPONENTS *****************/
app-filters-table {
  flex: 1;
}

/* TEMPLATE */
.header-actions {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
  .header-actions::after {
    content: "";
    display: block;
    left: -30px;
    bottom: 0;
    width: calc(100% + 60px);
    background-color: var(--separation-line);
    height: 4px;
    position: absolute;
    z-index: 1;
  }
.header-actions-wrap > * {
  margin-right: 10px;
  line-height: inherit;
  min-height: inherit;
}
.btn-header {
  padding: 8px 20px;
}
.part {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  width: auto;
  margin-top: 10px;
  padding: 10px 15px;
}
  .part:first-child {
    margin-top: 0;
  }
.wrap-file-actions {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}
.wrap-file-actions > * {
  margin-right: 20px;
  margin-bottom: 20px;
}
.wrap-file-actions > *:last-child {
  margin-right: 0;
}
.btn-action {
  padding: 3px 20px;
  border-radius: 50px;
  border: 1px solid var(--main-color);
  background: transparent;
  color: var(--main-color);
  transition: .3s;
}
  .btn-action:hover {
    background-color: var(--main-color);
    color: #fff;
  }
/* header */
.header__ctn {
  position: relative;
  color: var(--medium-text);
  h1 {
      display: inline-block;
      font-size: 1.3rem;
      margin-left: 10px;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
  }
  .icon-station {
      font-size: 20px;
  }
  &::after {
      $parentPad: 30px;
      content: '';
      display: block;
      left: -$parentPad;
      bottom: 0;
      width: calc(100% + #{2*$parentPad});
      background-color: var(--separation-line);
      height: 4px;
      position: absolute;
      z-index: 1;
  }
}
.title-page {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.title-page.title-page-two-column {
  display: flex;
  justify-content: space-between;
}
.title-page-left, .title-page-right {
  display: flex;
  align-items: center;
}
.title-page-right {
  font-size: 20px;
}
.state-indicator {
  margin-right: 15px;
}
.state-indicator span {
  margin-right: 10px;
}
.tab__ctn {
  display: flex;
  a {
      display: block;
      position: relative;
      text-transform: uppercase;
      color: var(--main-color-darker);
      padding: 18px 20px;
      font-weight: 600;
      &::after {
          content: '';
          display: block;
          width: 100%;
          height: 4px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
      }
      &.active::after {
          background-color: var(--main-color);
      }
  }
}
.detail-title {
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 1.1em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 5px;
  color: var(--main-color);
  border-bottom: 2px solid var(--main-color);
}
.delete-row {
  margin-top: 20px;
}
.el.disabled {
  opacity: .5;
  color: var(--dark-text);
  pointer-events: none;
}
.delete-subscription, .delete-badge, .delete-user, .delete-vehicle {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: underline;
}
.delete-subscription:hover, .delete-badge:hover, .delete-vehicle:hover {
  font-weight: 700;
}
.wrap-input-password {
  position: relative;
}

.wrap-icon-input-password {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  width: 30px;
  justify-content: center;
}


/*page*/
app-stations-list {
  display: block;
  height: 100%;
}

.station-dashboard-error{
  color: var(--alarm-level2) !important;
}

.btn-sm {
  min-height: 25px;
  line-height: 25px;
}

//COLOR INDICATORS ALARMS
.orange{
  color: #ff9900 !important;
}

.orange-border{
  border: 2px solid #ff9900 !important;
}

.red{
  color: #d80000 !important;
}

.red-border{
  border: 2px solid #d80000 !important;
}

.default {
  color: var(--main-color) !important;
}

.default-border{
  border: 2px solid var(--main-color) !important;
}

